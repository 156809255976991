import React, { Fragment, useState } from "react";
import { Dialog, Popover, Tab, Transition } from "@headlessui/react";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
import ButtonThird from "../../components/Button/ButtonThird";
import Checkbox from "../../components/Checkbox/Checkbox";
import convertNumbThousand from "../../utils/convertNumbThousand";
import ButtonClose from "../../components/Button/ButtonClose";
import Slider from "rc-slider";

// DEMO DATA
const typeOfAirlines = [
  {
    name: "Ethiopian Airlines",
    code: "ET",
  },
  {
    name: "Kenya Airways",
    code: "KQ",
  },
  {
    name: "Emirates",
    code: "EK",
  },
  {
    name: "Qatar Airways",
    code: "QR",
  },
  {
    name: "Egyptair",
    code: "MS",
  },
  {
    name: "Turkish Airlines",
    code: "TK",
  },
  // {
  //   name: "Rwandair",
  //   code: "WB",
  // },
  {
    name: "Fly Dubai",
    code: "FZ",
  },
  {
    name: "Saudia",
    code: "SV",
  },
  {
    name: "Gulf Air",
    code: "GF",
  },
  // {
  //   name: "Air Arabia",
  //   code: "G9",
  // },
];
const stopPoints = [
  {
    name: "Nonstop",
  },
  {
    name: "Up to 1 stops",
  },
  {
    name: "Up to 2 stops",
  },
  {
    name: "Any number of stops",
  },
];

const currencyList = [
  {
    name: "USD",
  },
  {
    name: "ETB",
  },
];

const channelTypeList = [
  {
    name: "NDC",
  },
  {
    name: "GDS",
  },
];

//
const TabFilters = ({
  searchData,
  searchFlight,
  NDCSearchFlight,
  carrier,
  setCarrier,
  airlinesStates,
  setAirlinesStates,
  currencyStates = "USD",
  setCurrencyStates,
  channelType,
  setChannelType,
}) => {
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  //
  const [isOnSale, setIsOnSale] = useState(true);
  const [rangePrices, setRangePrices] = useState([100, 5000]);
  const [tripTimes, setTripTimes] = useState(10);
  const [stopPontsStates, setStopPontsStates] = useState([]);
  const [currency, setCurrency] = useState(currencyStates);
  const [channelState, setChannelState] = useState(channelType || []);

  // const [airlinesStates, setAirlinesStates] = useState(null);
  // const [carrier, setCarrier] = useState(null);

  const handleClick = async () => {
    setCurrencyStates(currency);
    const search = {
      ...searchData,
      airlines: airlinesStates,
      airline: airlinesStates,
      CurrencyType: currency,
    };

    setCarrier(airlinesStates);

    await searchFlight(search);
    await NDCSearchFlight(search);
  };

  let [catTimes, setCatTimes] = useState({
    "Take Off": {
      Departure: [0, 24],
      Arrival: [0, 24],
    },
    Landing: {
      Departure: [0, 24],
      Arrival: [0, 24],
    },
  });

  const handleChannelClick = () => {
    localStorage.setItem("pairedRefs", JSON.stringify([]));
    setChannelType(channelState);
  };

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  //
  const handleChangeStopPoint = (checked, name) => {
    checked
      ? setStopPontsStates([...stopPontsStates, name])
      : setStopPontsStates(stopPontsStates.filter((i) => i !== name));
  };

  const handleCurrency = (checked, name) => {
    checked
      ? setCurrency(name)
      : setCurrency(currency.filter((i) => i !== name));
  };

  const handleChangeAirlines = (checked, name) => {
    checked ? setAirlinesStates(name) : setAirlinesStates(null);
  };

  const handleChannel = (checked, name) => {
    checked
      ? setChannelState([...channelState, name])
      : setChannelState(channelState.filter((i) => i !== name));
  };

  //

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-main-600 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabsTimeFlightTab = () => {
    return (
      <div>
        <Tab.Group>
          <Tab.List className="flex p-1 space-x-1 bg-main-200 rounded-xl">
            {Object.keys(catTimes).map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  `w-full py-2.5 text-sm leading-5 font-medium text-main dark:text-main-900 rounded-lg focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60 ${
                    selected
                      ? "bg-white dark:bg-neutral-800 shadow"
                      : " hover:bg-white/[0.15] dark:hover:bg-neutral-800"
                  }`
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {Object.values(catTimes).map((posts, idx) => {
              return (
                <Tab.Panel
                  key={idx}
                  className={
                    "bg-neutral-50 dark:bg-neutral-900 rounded-xl p-3 space-y-8 focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60"
                  }
                >
                  <span className=" text-neutral-6000 dark:text-neutral-300 text-sm">
                    {idx ? " Tokyo to Singapore" : " Singapore to Tokyo"}
                  </span>
                  <div></div>
                  <div className="space-y-3">
                    <div className="flex space-x-2">
                      <i className="text-lg las la-plane-departure"></i>
                      <span className="text-xs">Departure time:</span>
                      <span className="text-xs text-main">
                        {posts.Departure[0]}:00 - {posts.Departure[1]}
                        :00
                      </span>
                    </div>
                    <Slider
                      range
                      min={0}
                      max={24}
                      handleStyle={[
                        {
                          backgroundColor: "#FBA82C",
                          borderColor: "#FBA82C",
                        },
                        {
                          backgroundColor: "#FBA82C",
                          borderColor: "#FBA82C",
                        },
                      ]}
                      trackStyle={[
                        {
                          backgroundColor: "rgba(251, 168, 44, 0.3)",
                        },
                      ]}
                      defaultValue={posts.Departure}
                      onChange={(val) =>
                        setCatTimes((catTimes) =>
                          !idx
                            ? {
                                ...catTimes,
                                "Take Off": {
                                  ...posts,
                                  Departure: val,
                                },
                              }
                            : {
                                ...catTimes,
                                Landing: {
                                  ...posts,
                                  Departure: val,
                                },
                              }
                        )
                      }
                      allowCross={false}
                    />
                  </div>
                  <div className="space-y-3">
                    <div className="flex space-x-2">
                      <i className="text-lg las la-plane-arrival"></i>
                      <span className="text-xs">Arrival time:</span>
                      <span className="text-xs text-main dark:text-main-400">
                        {posts.Arrival[0]}:00 - {posts.Arrival[1]}:00
                      </span>
                    </div>
                    <Slider
                      className="text-100"
                      range
                      min={0}
                      max={24}
                      handleStyle={[
                        {
                          backgroundColor: "#FBA82C",
                          borderColor: "#FBA82C",
                        },
                        {
                          backgroundColor: "#FBA82C",
                          borderColor: "#FBA82C",
                        },
                      ]}
                      trackStyle={[
                        {
                          backgroundColor: "rgba(251, 168, 44, 0.3)",
                        },
                      ]}
                      defaultValue={posts.Arrival}
                      onChange={(val) =>
                        setCatTimes((catTimes) =>
                          !idx
                            ? {
                                ...catTimes,
                                "Take Off": {
                                  ...posts,
                                  Arrival: val,
                                },
                              }
                            : {
                                ...catTimes,
                                Landing: {
                                  ...posts,
                                  Arrival: val,
                                },
                              }
                        )
                      }
                      allowCross={false}
                    />
                  </div>
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  };

  const renderTabsTypeOfAirlines = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              onClose={() => {
                setAirlinesStates(null);
              }}
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border-1 border-black  focus:outline-none text-black
               ${open ? "!border-black " : ""}
                ${carrier ? "!border-black bg-main-50" : ""}
                `}
            >
              <span>{carrier ? carrier : "Filter by Airlines:"} </span>
              {!carrier ? (
                <i className="las la-angle-down ml-2"></i>
              ) : (
                <span onClick={() => setAirlinesStates(null)}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-xl">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white border border-neutral-200 ">
                  <div className="relative flex flex-col px-5  py-3 space-y-2">
                    <Checkbox
                      name="All Airlines"
                      label="All Airlines"
                      defaultChecked={carrier === null}
                      onChange={(checked) => {
                        handleChangeAirlines(checked, null);
                      }}
                    />
                    <hr />
                    <div className="grid grid-cols-2 gap-3">
                      {typeOfAirlines.map((item) => (
                        <div key={item.name} className="">
                          <Checkbox
                            name="radio"
                            type="radio"
                            label={item.name}
                            defaultChecked={carrier === item.code}
                            onChange={(checked) =>
                              handleChangeAirlines(checked, item.code)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-3 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setCarrier((prev) => (prev === null ? null : null));
                        setAirlinesStates((prev) =>
                          prev === null ? null : null
                        );
                        close();
                        handleClick();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        handleClick();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderChannelType = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              onClose={() => {}}
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border-1 border-black  focus:outline-none text-black
               ${open ? "!border-black " : ""}
               
                `}
            >
              <span>
                {channelType?.length > 0
                  ? channelType?.map((item, i) =>
                      i === 0 ? item : ` & ${item}`
                    )
                  : "Filter by Channels:"}{" "}
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-xl">
                <div className="overflow-hidden rounded</div>-2xl shadow-xl bg-white border border-neutral-200 ">
                  <div className="relative flex flex-col px-5  py-3 space-y-2">
                    <div className="grid grid-cols-2 gap-3">
                      {channelTypeList?.map((item) => (
                        <div key={item.name} className="">
                          <Checkbox
                            name="channel"
                            type="checkbox"
                            label={item.name}
                            disabled={channelState?.length === 1}
                            defaultChecked={channelState?.includes(item.name)}
                            onChange={(checked) =>
                              handleChannel(checked, item.name)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-3 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Close
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        handleChannelClick();
                      }}
                      disabled={channelState?.length === 0}
                      sizeClass={`px-4 py-2 sm:px-5
                        ${channelState?.length === 0 ? "bg-neutral-300" : ""}`}
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsStopPoints = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none 
              ${open ? "!border-main " : ""}
                ${!!stopPontsStates.length ? "!border-main bg-main-50" : ""}
                `}
            >
              <span>Stop points</span>
              {!stopPontsStates.length ? (
                <i className="las la-angle-down ml-2"></i>
              ) : (
                <span onClick={() => setStopPontsStates([])}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {stopPoints.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={stopPontsStates.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeStopPoint(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setStopPontsStates([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTCurrency = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button className="flex items-center justify-center px-4 py-2 text-sm rounded-full border-1 border-black focus:outline-none text-black">
              <span>Currency</span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {currencyList.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name="radio"
                          type="radio"
                          label={item.name}
                          defaultChecked={currency === item.name}
                          onChange={(checked) =>
                            handleCurrency(checked, item.name)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-2 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCurrencyStates([]);
                        setCurrency(null);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        handleClick();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTimeFlight = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-main " : ""
              }`}
            >
              <span>Flight time</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900   border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {renderTabsTimeFlightTab()}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTripTime = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-main bg-main-100 text-main focus:outline-none `}
            >
              <span>less than {tripTimes} hours</span>
              {renderXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <div className="font-medium">
                        Trip time:
                        <span className="text-sm font-normal ml-1 text-main">{` <${tripTimes} hours`}</span>
                      </div>

                      <Slider
                        min={1}
                        max={72}
                        handleStyle={[
                          {
                            backgroundColor: "#FBA82C",
                            borderColor: "#FBA82C",
                          },
                        ]}
                        trackStyle={[
                          {
                            backgroundColor: "rgba(251, 168, 44, 0.3)",
                          },
                        ]}
                        defaultValue={tripTimes}
                        onChange={(e) => setTripTimes(e)}
                      />
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-main bg-main-100 text-main focus:outline-none `}
            >
              <span>
                {`${convertNumbThousand(
                  rangePrices[0]
                )} birr - ${convertNumbThousand(rangePrices[1])} birr`}{" "}
              </span>
              {renderXClear()}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price per person</span>
                      <Slider
                        range
                        min={100}
                        max={5000}
                        handleStyle={[
                          {
                            backgroundColor: "#FBA82C",
                            borderColor: "#FBA82C",
                          },
                          {
                            backgroundColor: "#FBA82C",
                            borderColor: "#FBA82C",
                          },
                        ]}
                        trackStyle={[
                          {
                            backgroundColor: "rgba(251, 168, 44, 0.3)",
                          },
                        ]}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(e) => setRangePrices(e)}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              birr
                            </span>
                          </div>
                          <input
                            type="text"
                            name="minPrice"
                            disabled
                            id="minPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-2 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                            <span className="text-neutral-500 sm:text-sm">
                              birr
                            </span>
                          </div>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-2 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird onClick={close} sizeClass="px-4 py-2 sm:px-5">
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabOnSale = () => {
    return (
      <div
        className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer transition-all ${
          isOnSale
            ? "border-main bg-main-100 text-main"
            : "border-neutral-300 dark:border-neutral-700"
        }`}
        onClick={() => setIsOnSale(!isOnSale)}
      >
        <span>On sale</span>
        {isOnSale && renderXClear()}
      </div>
    );
  };

  const renderMoreFilterItem = (data) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              subLabel={item.description}
              label={item.name}
              defaultChecked={!!item.defaultChecked}
            />
          ))}
        </div>
      </div>
    );
  };

  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-main bg-main-100 text-main focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilter}
        >
          <span>
            <span className="hidden sm:inline">Flights</span> filters (3)
          </span>
          {renderXClear()}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Flight filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Airlines</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(typeOfAirlines)}
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Stop points</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(stopPoints)}
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={0}
                                max={2000}
                                defaultValue={[0, 1000]}
                                allowCross={false}
                                onChange={(e) => setRangePrices(e)}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">
                          Strip times
                          <span className="text-sm font-normal ml-1 text-main">{` <${tripTimes} hours`}</span>
                        </h3>
                        <div className="mt-6 relative ">
                          <Slider
                            min={1}
                            max={72}
                            defaultValue={tripTimes}
                            onChange={(e) => setTripTimes(e)}
                          />
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Flight times</h3>
                        <div className="relative flex flex-col py-5 space-y-5">
                          {renderTabsTimeFlightTab()}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={closeModalMoreFilter}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      {/* FOR DESKTOP */}
      <div className="hidden lg:flex space-x-4 px-5">
        {renderTabsTypeOfAirlines()}
        {renderTCurrency()}
        {/* {renderChannelType()} */}
        {/* {renderTabsTripTime()}
        {renderTabsStopPoints()}
        {renderTabsPriceRage()}
        {renderTabsTimeFlight()}
        {renderTabOnSale()} */}
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      <div className="flex lg:hidden space-x-4">
        {/* {renderTabMobileFilter()} */}
        {/* {renderTabOnSale()} */}
      </div>
    </div>
  );
};

export default TabFilters;
