import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./user/authSlice";
import userReducer from "./user/userSlice";
import agentReducer from "./agent/agentSlice";
import requestReducer from "./request/requestSlice";
import bookingReducer from "./booking/bookingSlice";
import billingReducer from "./billing/billingSlice";
import corporateReducer from "./corporate/corporateSlice";
import dashboardReducer from "./dashboard/dashboardSlice";
import enterpriseReducer from "./enterprise/enterpriseSlice";
import exchangeReducer from "./exchange/exchangeSlice";
import discountReducer from "./discount/discountSlice";
import modalReducer from "./modal/modalSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    agent: agentReducer,
    booking: bookingReducer,
    billing: billingReducer,
    request: requestReducer,
    corporate: corporateReducer,
    enterprise: enterpriseReducer,
    dashboard: dashboardReducer,
    exchange: exchangeReducer,
    discount: discountReducer,
    modals: modalReducer,
  },
});
