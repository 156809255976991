import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";

const BookingDetailModal = ({ openDetail, setOpenDetail, booking }) => {
  return (
    <>
      <Modal
        show={openDetail}
        className="modal fade"
        onHide={() => setOpenDetail(false)}
        size="lg"
        centered
      >
        <div className="">
          <div className="modal-header bg-primary text-white rounded-t-2xl">
            <h1 className="text-black font-bold  text-xl">Booking Details</h1>
            <button
              type="button"
              className="btn-close btn-close-white"
              onClick={() => setOpenDetail(false)}
            ></button>
          </div>

          <div className="modal-body text-black">
            <h6 className="border-bottom pb-2 mb-3 text-lg font-semibold">
              Passenger Information
            </h6>
            {booking?.passengers?.map((passenger, index) => (
              <div className="flex justify-around py-2">
                <div className="ml-2 md:ml-10 w-1/2">
                  <p>
                    <strong>Name:</strong> {passenger?.firstName}{" "}
                    {passenger?.lastName}
                  </p>
                  <p>
                    <strong>Email:</strong> {passenger?.email}
                  </p>
                  <p>
                    <strong>Phone:</strong> {passenger?.phoneNumber}
                  </p>
                  <p>
                    <strong>Nationality:</strong> {passenger?.nationality}
                  </p>
                  <p>
                    <strong>DOB:</strong> {moment(passenger?.dob).format("LL")}
                  </p>
                  <p>
                    <strong>Gender:</strong> {passenger?.gender}
                  </p>
                </div>
                <div className="w-1/2">
                  <p>
                    <strong>Passport Number:</strong>{" "}
                    {passenger?.passportNumber}
                  </p>
                  <p>
                    <strong>Origin Id:</strong> {passenger?.originId}
                  </p>
                  <p>
                    <strong>Status:</strong> {booking?.status}
                  </p>
                  <p>
                    <strong>Payment Status:</strong> {booking?.paymentStatus}
                  </p>
                </div>
              </div>
            ))}

            <h6 className="border-bottom pb-2 mb-3 mt-4 text-lg font-semibold">
              Booking Details
            </h6>

            <div className="flex justify-around">
              <div className="ml-2 md:ml-10 w-1/2">
                <p>
                  <strong>Origin:</strong> {booking?.flight?.origin}
                </p>
                <p>
                  <strong>Destination:</strong> {booking?.flight?.destination}
                </p>
                <p>
                  <strong>Carrier:</strong> {booking?.flight?.carrier}
                </p>
                <p>
                  <strong>Flight Type:</strong> {booking?.flight?.flightType}
                </p>
                <p>
                  <strong>Flight Price:</strong>{" "}
                  {booking?.flight?.price + (booking?.NDCServiceCharge || 0)}
                </p>
                <p>
                  <strong>Total Price:</strong> {booking?.totalPrice}
                </p>
              </div>

              <div className="w-1/2">
                {/* <p>
                  <strong>Departure Date:</strong>{" "}
                  {moment(booking?.flight?.departureTime).format("LL")}
                </p>
                <p>
                  <strong>Arrival Date:</strong>{" "}
                  {moment(booking?.flight?.arrivalTime).format("LL")}
                </p> */}
                {/* <p>
                  <strong>PNR:</strong> {booking?.flight?.PNR}
                </p>
                <p>
                  <strong>Retrieval PNR:</strong>{" "}
                  {booking?.flight?.retrievalPnr}
                </p> */}
                <p>
                  <strong>Reservation PNR:</strong>{" "}
                  {booking?.flight?.providerPnr}
                </p>
                <p>
                  <strong>Ticket Number:</strong>{" "}
                  {booking?.flight?.ticketNumber?.join(", ")}
                </p>
                <p>
                  <strong>Routes:</strong>{" "}
                  {booking?.flight?.routes?.map((route) => (
                    <span className="mr-2">
                      {route?.flights.map((flight, index) => {
                        const separator =
                          index === route?.flights.length - 1 ? "" : "-";
                        return flight + separator;
                      })}
                    </span>
                  ))}
                </p>
                <p>
                  <strong>Created At:</strong>{" "}
                  {moment(booking?.createdAt).format("LL")}
                </p>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-outline-primary hover:bg-main-700"
              onClick={() => setOpenDetail(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BookingDetailModal;
