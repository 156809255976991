const createPassenger = (passengers, sortedPassengers, passportData) => {
  return passengers.map((passenger, index) => ({
    ...(index === 0 && {
      Contacts: {
        Contact: [
          {
            PhoneContact: {
              Number: [
                {
                  CountryCode: "251",
                  value: passenger.phone,
                },
              ],
            },
            EmailContact: {
              Address: {
                value: "natnaelmekonnent@gmail.com",
              },
            },
            AddressContact: {
              Street: ["Pontes Avenue"],
              CityName: "Addis Ababa",
              PostalCode: "1000",
              CountryCode: {
                value: "ET",
              },
            },
            ContactRefuseInd: true,
          },
        ],
      },
    }),
    PassengerIDInfo: {
      PassengerDocument: [
        {
          Type: "PT",
          ID: passportData?.[index]?.DocumentNumber,
          DateOfExpiration: passportData?.[index]?.expiryDate,
          CountryOfIssuance: "ET",
          CountryOfResidence: "ET",
        },
      ],
    },
    ObjectKey: sortedPassengers?.[index]?.ObjectKey,
    ...(sortedPassengers?.[index]?.PTC?.value === "INF" && {
      PassengerAssociation: sortedPassengers?.[0]?.ObjectKey,
    }),
    Gender: {
      value: passenger.Gender === "M" ? "Male" : "Female",
    },
    PTC: {
      value: sortedPassengers?.[index]?.PTC?.value,
    },
    Age: {
      BirthDate: {
        value: passenger.DOB,
      },
    },
    Name: {
      Given: [
        {
          value: (passenger.FirstName + " " + passenger.MiddleName).trim(),
        },
      ],
      Title: passenger.Prefix,
      Surname: {
        value: passenger.LastName,
      },
    },
  }));
};

const NDCBookingReq = (data, passengersInfo, passportData, basePrice) => {
  const Travelers = data?.DataLists?.AnonymousTravelerList?.AnonymousTraveler;

  const sortPassengerData = (data) => {
    const order = { ADT: 1, CHD: 2, INF: 3 };
    return data.sort((a, b) => order[a.PTC.value] - order[b.PTC.value]);
  };

  const sortedPassengers = sortPassengerData(Travelers);

  const getOfferItemID = () => {
    let offerItem =
      data?.PricedFlightOffers?.PricedFlightOffer?.[0]?.OfferPrice?.map(
        (item) => {
          return {
            OfferItemID: {
              Owner: data.PricedFlightOffers.PricedFlightOffer[0].OfferID.Owner,
              value: item.OfferItemID,
            },
          };
        }
      );
    return offerItem;
  };

  function modifySegment(data) {
    return data?.map((segment) => {
      const { SegmentKey, FlightDetail, ...rest } = segment;
      return rest;
    });
  }

  function removeSegment(data) {
    return data?.map((segment) => {
      const { FlightDetail, ...rest } = segment;
      return rest;
    });
  }

  // function getSegment(segments, offer) {
  //   return offer?.RequestedDate?.Associations?.flatMap((association) => {
  //     return association?.ApplicableFlight?.FlightSegmentReference?.map(
  //       (segment) => {
  //         return {
  //           ...segments?.FlightSegment?.find(
  //             (item) => item.SegmentKey === segment.ref
  //           ),
  //           ClassOfService: segment.ClassOfService,
  //           Details: segments?.FlightSegment?.find(
  //             (item) => item.SegmentKey === segment.ref
  //           ).FlightDetail,
  //         };
  //       }
  //     );
  //   });
  // }

  // function getSegment2(segments, offer) {
  //   return segments?.SegmentReferences?.value?.map((seg) => {
  //     return offer?.RequestedDate?.Associations?.flatMap((association) => {
  //       return association?.ApplicableFlight?.FlightSegmentReference?.map(
  //         (segment) => {
  //           // console.log(segment);
  //           // console.log(seg);
  //           return {
  //             seg,
  //             // ClassOfService: seg === segment.ref && segment.ClassOfService,
  //             // Details: seg?.find((item) => item.SegmentKey === segment.ref)
  //             //   .FlightDetail,
  //           };
  //         }
  //       );
  //     });
  //   });
  // }

  function getClassOfService(ref, offer) {
    // Find the first association that matches the segment reference
    for (const association of offer?.RequestedDate?.Associations || []) {
      const matchedSegment =
        association?.ApplicableFlight?.FlightSegmentReference?.find(
          (segment) => segment.ref === ref
        );

      if (matchedSegment) {
        return matchedSegment.ClassOfService; // Return ClassOfService if found
      }
    }

    return null; // Return null if no match is found
  }

  function getSegment2(segments, offer) {
    return segments?.SegmentReferences?.value?.map((seg) => {
      // console.log(segment);
      // console.log(seg);
      return {
        seg,
        ClassOfService: getClassOfService(seg, offer),
        // Details: seg?.find((item) => item.SegmentKey === segment.ref)
        //   .FlightDetail,
      };
    });
  }

  function getClassService(segments, offer) {
    for (const association of offer?.RequestedDate?.Associations || []) {
      const matchedService =
        association?.ApplicableFlight?.FlightSegmentReference?.find(
          (service) => service.ref === segments.SegmentKey
        );

      if (matchedService) {
        return {
          ClassOfService: matchedService.ClassOfService,
          Details: segments.FlightDetail,
        };
      }
    }

    return null;
  }

  function getSegment(segments, offer) {
    return segments?.map((segment) => {
      return {
        ...segment,
        ...getClassService(segment, offer),
      };
    });
  }

  let flightList = data?.DataLists?.FlightList.Flight;
  let flightSegments = data?.DataLists?.FlightSegmentList.FlightSegment;
  let OD = data?.DataLists?.OriginDestinationList.OriginDestination;
  // const result = flightList.map((flight) => {
  //   return flight.SegmentReferences.value.map((segmentKey) =>
  //     flightSegments.find((segment) => segment.SegmentKey === segmentKey)
  //   );
  // });

  function getFlightSegment() {
    return flightList.map((flight) => {
      return flight.SegmentReferences.value.map((segmentKey) =>
        flightSegments.find((segment) => {
          return segment.SegmentKey === segmentKey;
        })
      );
    });
  }

  console.log(getFlightSegment());

  // console.log({ result });

  function removeFareList() {
    return (
      data.DataLists.FareList?.FareGroup?.map((fare) => {
        if (!fare.Fare || !fare.Fare.FareDetail) {
          return fare;
        }

        const newFare = { ...fare, Fare: { ...fare.Fare } };
        delete newFare.Fare.FareDetail;

        return newFare;
      }) || []
    );
  }

  function getOfferItem() {
    return data?.PricedFlightOffers?.PricedFlightOffer?.[0]?.OfferPrice?.map(
      (offer, index) => {
        return {
          OfferItemID: {
            Owner: data.PricedFlightOffers.PricedFlightOffer[0].OfferID.Owner,
            value: offer.OfferItemID,
          },
          OfferItemType: {
            DetailedFlightItem: [
              {
                Price: {
                  BaseAmount: offer.RequestedDate.PriceDetail.BaseAmount,
                  Taxes: {
                    Total: offer.RequestedDate.PriceDetail.Taxes.Total,
                  },
                },
                // OriginDestination: [
                //   {
                //     Flight:
                //       index === 0
                //         ? data.DataLists.FlightSegmentList.FlightSegment
                //         : modifySegment(
                //             data.DataLists.FlightSegmentList.FlightSegment
                //           ),
                //     ...(index === 0 && { OriginDestinationKey: "OD1" }),
                //   },
                // ],
                // OriginDestination: [
                //   {
                //     Flight:
                //       index === 0
                //         ? removeSegment(
                //             getSegment(data.DataLists.FlightSegmentList, offer)
                //           )
                //         : modifySegment(
                //             getSegment(data.DataLists.FlightSegmentList, offer)
                //           ),

                //     ...(index === 0 && { OriginDestinationKey: "OD1" }),
                //   },
                // ],
                OriginDestination: getFlightSegment()?.map((segment, i) => {
                  return {
                    Flight:
                      index === 0
                        ? removeSegment(getSegment(segment, offer))
                        : modifySegment(getSegment(segment, offer)),
                    ...(index === 0 && {
                      OriginDestinationKey: OD[i].OriginDestinationKey,
                    }),
                  };
                }),
                // OriginDestination: flightList?.map((segment, index) => {
                //   console.log({ seg: segment });
                //   return {
                //     Flight:
                //       index === 0
                //         ? // ? removeSegment(
                //           //     getSegment2(
                //           //       segment,
                //           //       data.DataLists.FlightSegmentList,
                //           //       offer
                //           //     )
                //           //   )
                //           // : modifySegment(
                //           //     getSegment2(
                //           //       segment,
                //           //       data.DataLists.FlightSegmentList,
                //           //       offer
                //           //     )
                //           //   ),
                //           getSegment2(segment, offer)
                //         : getSegment2(segment, offer),

                //     OriginDestinationKey: `OD${index + 1}`,
                //   };
                // }),

                refs: offer.RequestedDate.Associations[0].AssociatedTraveler
                  .TravelerReferences,
              },
            ],
          },
        };
      }
    );
  }

  return {
    Query: {
      OrderItems: {
        ShoppingResponse: {
          Owner: data.PricedFlightOffers.PricedFlightOffer[0].OfferID.Owner,
          ResponseID: {
            value: data.ShoppingResponseID.ResponseID.value,
          },
          Offers: {
            Offer: [
              {
                OfferID: data.PricedFlightOffers.PricedFlightOffer[0].OfferID,
                refs: data.PricedFlightOffers.PricedFlightOffer[0].refs,
                OfferItems: {
                  // OfferItem: [
                  //   {
                  //     OfferItemID: {
                  //       Owner:
                  //         data.PricedFlightOffers.PricedFlightOffer[0].OfferID
                  //           .Owner,
                  //       value:
                  //         data.PricedFlightOffers.PricedFlightOffer[0]
                  //           .OfferPrice[0].RequestedDate.Associations[0]
                  //           .AssociatedTraveler.TravelerReferences[0], // Passenger code
                  //     },
                  //   },
                  // ],
                  // OfferItem: getOfferItemID(
                  //   data.PricedFlightOffers.PricedFlightOffer[0],
                  // ),
                  // OfferItem: [
                  //   {
                  //     OfferItemID: {
                  //       Owner:
                  //         data.PricedFlightOffers.PricedFlightOffer[0].OfferID
                  //           .Owner,
                  //       value:
                  //         data.PricedFlightOffers.PricedFlightOffer[0]
                  //           .OfferPrice[0].OfferItemID,
                  //     },
                  //   },
                  // ],
                  OfferItem: getOfferItemID(),
                },
              },
            ],
          },
        },
        // OfferItem: [
        //   {
        //     OfferItemID: {
        //       Owner: data.PricedFlightOffers.PricedFlightOffer[0].OfferID.Owner,
        //       value:
        //         data.PricedFlightOffers.PricedFlightOffer[0].OfferPrice[0]
        //           .OfferItemID,
        //       // refs: [{}],
        //       // Channel: "NDC",
        //     },
        //     OfferItemType: {
        //       // // OtherItem: [
        //       // //   {
        //       // //     refs: [{}],
        //       // //     Price: {
        //       // //       SimpleCurrencyPrice: {
        //       // //         value: 34933,
        //       // //         Code: "INR",
        //       // //       },
        //       // //     },
        //       // //   },
        //       // // ],
        //       // DetailedFlightItem: [
        //       //   {
        //       //     // FareDetail: {
        //       //     //   FareComponent:
        //       //     //     data.PricedFlightOffers.PricedFlightOffer[0].OfferPrice[0]
        //       //     //       .FareDetail.FareComponent[0],
        //       //     // },
        //       //     Price: {
        //       //       BaseAmount:
        //       //         data.PricedFlightOffers.PricedFlightOffer[0].OfferPrice[0]
        //       //           .RequestedDate.PriceDetail.BaseAmount,
        //       //       Taxes: {
        //       //         Total:
        //       //           data.PricedFlightOffers.PricedFlightOffer[0]
        //       //             .OfferPrice[0].RequestedDate.PriceDetail.Taxes.Total,
        //       //       },
        //       //     },
        //       //     OriginDestination: [
        //       //       {
        //       //         Flight: data.DataLists.FlightSegmentList.FlightSegment,
        //       //         OriginDestinationKey: "OD1",
        //       //       },
        //       //     ],
        //       //     refs: data.PricedFlightOffers.PricedFlightOffer[0]
        //       //       .OfferPrice[0].RequestedDate.Associations[0]
        //       //       .AssociatedTraveler.TravelerReferences,
        //       //   },
        //       // ],
        //       // SeatItem: [
        //       //   {
        //       //     Descriptions: {
        //       //       Description: [
        //       //         {
        //       //           Text: {
        //       //             value: "string",
        //       //           },
        //       //         },
        //       //       ],
        //       //     },
        //       //     Price: {
        //       //       Total: {
        //       //         value: 0,
        //       //         Code: "string",
        //       //       },
        //       //     },
        //       //     SeatAssociation: [
        //       //       {
        //       //         SegmentReferences: {
        //       //           value: "string",
        //       //         },
        //       //         TravelerReference: "string",
        //       //       },
        //       //     ],
        //       //     Location: {
        //       //       Column: "string",
        //       //       Characteristics: {
        //       //         Characteristic: [
        //       //           {
        //       //             Remarks: {
        //       //               Remark: [
        //       //                 {
        //       //                   value: "string",
        //       //                 },
        //       //               ],
        //       //             },
        //       //             Code: "string",
        //       //           },
        //       //         ],
        //       //       },
        //       //       Row: {
        //       //         Number: {
        //       //           value: "string",
        //       //         },
        //       //       },
        //       //     },
        //       //   },
        //       // ],
        //     },
        //   },
        // ],
        OfferItem: getOfferItem(),
      },
      DataLists: {
        PriceClassList: data.DataLists.PriceClassList,
        FareList: { FareGroup: removeFareList() },
        // ServiceList: data.DataLists.ServiceList,
      },
      Passengers: {
        Passenger: createPassenger(
          passengersInfo,
          sortedPassengers,
          passportData
        ),
      },
      // Passenger: passengersInfo.map(createPassenger),
      Metadata: data.Metadata,
      // For Instant Ticketing
      // Payments: {
      //   Payment: [
      //     {
      //       Amount: {
      //         value: basePrice,
      //         Code: data.PricedFlightOffers.PricedFlightOffer[0].OfferPrice[0]
      //           .RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.Code,
      //       },
      //       Method: {
      //         Cash: {
      //           CashInd: true,
      //         },
      //       },
      //     },
      //   ],
      // },
    },
  };
};

export default NDCBookingReq;
