import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../../../images/logo.png";
import Loading from "../../../Loading/index.jsx";
import { getCompanyById } from "../../../../redux2/user/userSlice.js";
import { getCarrierInfo, getFlightName } from "../../../../utils/flightInfo.js";
import NDCTicketDetail from "./NDCTicketDetail.jsx";
import "../style.css";

export const NDCTicketInfo = React.forwardRef(
  ({ ticketData, companyInfo }, ref) => {
    const dispatch = useDispatch();

    const { company, isLoading } = useSelector((state) => state.user);

    useEffect(() => {
      dispatch(getCompanyById(companyInfo));
    }, [dispatch, companyInfo]);

    const { Passenger } = ticketData?.Passengers;
    const { TicketDocInfo } = ticketData?.TicketDocInfos || {};
    const { Order } = ticketData;

    let carrierLogo = getCarrierInfo(Order[0]?.OrderID?.Owner)?.Logo;

    const flightSegment = Order[0]?.OrderItems?.OrderItem?.reduce(
      (segments, item) => {
        if (item?.FlightItem?.OriginDestination && segments.length === 0) {
          segments.push(item.FlightItem.OriginDestination);
        }
        return segments;
      },
      []
    );

    const destination =
      flightSegment?.[0]?.[0]?.Flight?.at(-1)?.Arrival?.AirportCode?.value;

    const flattenedFlights = flightSegment.flatMap((outerArray) =>
      outerArray.flatMap((flightObject) => flightObject.Flight)
    );

    if (isLoading) return <Loading />;

    return (
      <div className="mx-10 my-4 text-black ticket-print " ref={ref}>
        <div className="flight-header my-1 font-semibold text-lg">
          <h1>Trip to {getFlightName(destination)}</h1>
        </div>
        <hr />
        <div className="passenger-header">
          <div className="">
            <p>PREPARED FOR </p>
            <div className="flex flex-col ">
              {Passenger?.map((passenger, index) => (
                <h1
                  className="font-normal text-xl tracking-wide text-gray-600"
                  key={index}
                >
                  {passenger?.Name?.Title && passenger?.Name?.Title + ". "}
                  <span className="uppercase font-semibold ">
                    {passenger?.Name?.Given[0]?.value +
                      " " +
                      passenger?.Name?.Surname?.value}
                  </span>
                </h1>
              ))}
            </div>
            {/* <div className="flex flex-row justify-between gap-5 text-md font-light text-gray-500 tracking-wide">
              <p className="">
                Confirmation number{": "}
                <span className="font-bold">
                  {
                    airReservation?.SupplierLocator?._attributes
                      ?.SupplierLocatorCode
                  }
                </span>
              </p>
              <p className="">
                Reservation Code{": "}
                <span className="font-bold">
                  {reservation?._attributes?.LocatorCode}
                </span>
              </p>
            </div> */}
          </div>
          <div className="text-sm">
            <p>Passport Number</p>
            <div className="flex flex-col">
              {Passenger?.map((passenger, index) => (
                <h1
                  className="font-normal text-lg tracking-wide text-gray-600"
                  key={index}
                >
                  {passenger?.PassengerIDInfo?.PassengerDocument[0]?.ID}
                </h1>
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={carrierLogo}
              alt="logo"
              className="object-cover w-32 h-20"
            />
          </div>
        </div>
        <div className="line"></div>

        {flattenedFlights.map((segment, index) => {
          return (
            <NDCTicketDetail
              data={segment}
              passengerList={Passenger}
              TicketDocInfo={TicketDocInfo}
              key={index}
              index={index}
            />
          );
        })}

        <div className="line"></div>

        <div className="my-2 p-2 border-1 border-slate-400 ">
          <h1 className="text-md font-semibold py-2 text-grey-700">
            Baggage Information
          </h1>
          <div className="row">
            <p className="col-2 sm:px-10">Flight</p>
            <p className="col-3">Baggage Allowance</p>
            <p className="col-2">Fare Basis</p>
            <p className="col-3">Not Valid Before</p>
            <p className="col-2">Not Valid After</p>
          </div>
          {/* <div className="row text-xs text-slate-500">
            {passengerList?.length > 1
              ? passengerList?.map((_, i) =>
                  airReservation?.AirSegment instanceof Array
                    ? airReservation?.AirSegment.map((item, index) => {
                        return FlightDetail(
                          airReservation?.AirPricingInfo?.[i]?.FareInfo[
                            index
                          ] ||
                            airReservation?.AirPricingInfo?.FareInfo?.[index],
                          item?._attributes,
                          i
                        );
                      })
                    : FlightDetail(
                        airReservation?.AirPricingInfo?.[i]?.FareInfo ||
                          airReservation?.AirPricingInfo?.FareInfo,
                        airReservation?.AirSegment._attributes,
                        i
                      )
                )
              : airReservation?.AirSegment instanceof Array
              ? airReservation?.AirSegment.map((item, index) => {
                  return FlightDetail(
                    airReservation?.AirPricingInfo?.FareInfo?.[index],
                    item?._attributes
                  );
                })
              : FlightDetail(
                  airReservation?.AirPricingInfo?.FareInfo,
                  airReservation?.AirSegment?._attributes
                )}
          </div> */}
        </div>

        <div className="flex justify-between px-1 text-xs">
          {company ? (
            <>
              {/* <h1 className="text-xl font-bold">Booking Technologies PLC</h1> */}
              <h1 className="text-xl font-bold">{company?.name}</h1>
              <div className="text-xs">
                <div className="space-y-1">
                  <h3>Head Office: {company?.address} </h3>
                  <h3 className="sm:ml-3 text-center">
                    {/* Addis Ababa, Ethiopia */}
                    Ethiopia
                  </h3>
                </div>
              </div>
              <div>
                <h2>Agency Office Phone:</h2>
                <h3>{company?.officeNumber}</h3>
                <h3>{company?.officeNumber2}</h3>
              </div>
            </>
          ) : (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "150px", height: "38px" }}
              />
              <div className="text-xs">
                <div className="space-y-1">
                  <h3>Head Office: Near the National Theater </h3>
                  <h3 className="sm:ml-14 text-center">
                    Addis Ababa, Ethiopia
                  </h3>
                </div>
              </div>
              <div>
                <h2>Agency Office Phone:</h2>
                <h3>+251 115 511333 </h3>
                <h3>+251 115 514666</h3>
              </div>
              <div>
                <h2>Agency Mobile Phone:</h2>
                <h3>+251 922 238193</h3>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

function FlightDetail(fareInfo, segment, index) {
  return (
    <div className="row sm:mx-2">
      <h1 className="col-2">
        {segment?.Origin} - {segment?.Destination}
      </h1>
      <div className="col-3">
        {fareInfo?.BaggageAllowance?.NumberOfPieces ? (
          <h1>
            <span className="font-semibold text-grey-700">
              {fareInfo?.BaggageAllowance?.NumberOfPieces?._text}{" "}
            </span>
            Piece(s) Plan
          </h1>
        ) : (
          <h1>
            <span className="font-semibold text-grey-700">
              {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Value}{" "}
            </span>
            {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Unit} Plan
          </h1>
        )}
      </div>
      <div className="col-2">
        {/* <h2 className="font-semibold text-grey-700">Fare Basis:</h2> */}
        <div className="text-grey-700">{fareInfo?._attributes?.FareBasis}</div>
      </div>
      <div className="col-3">
        {/* <h2 className="font-semibold text-grey-700">Not Valid Before:</h2> */}
        <h1 className="text-grey-700">
          {moment(fareInfo?._attributes?.NotValidBefore).format("MMM DD, YYYY")}
        </h1>
      </div>
      <div className="col-2">
        {/* <h2 className="font-semibold text-grey-700">Not Valid After:</h2> */}
        <h1 className="text-grey-700">
          {" "}
          {moment(fareInfo?._attributes?.NotValidAfter).format("MMM DD, YYYY")}
        </h1>
      </div>
    </div>
  );
}
