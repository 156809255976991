import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

import ShoppingItems from "./ShoppingItems";
import { PricingReq } from "../AirPricing/PricingReq";
import { getNDCAirPricing } from "../Services/AirPricing";
import {
  getNDCSC,
  groupDataListByReference,
  groupOffersByReference,
} from "../helpers";
import { getNDCTotalPrice } from "../../../utils/serviceChargePrice";
import { useSelector } from "react-redux";

const Shopping = ({
  dataSet,
  setLoading,
  query,
  flight,
  company,
  corporate,
  discount,
}) => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const adults = q.get("adults");
  const children = q.get("children");

  const totalPassengers = parseInt(adults) + parseInt(children);

  const [selectedOffer, setSelectedOffer] = useState(null);

  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const groupedOffers = groupOffersByReference(
    dataSet?.OffersGroup?.AirlineOffers?.[0].AirlineOffer
  );

  let Metadata = dataSet?.Metadata?.Other?.OtherMetadata;

  const onSelect = async (offer, oneWayLength, serviceCharge, NDC_SC) => {
    const flightList =
      offer?.PricedOffer?.OfferPrice?.[0].RequestedDate?.Associations?.map(
        (item) => {
          return item.ApplicableFlight.FlightReferences.value;
        }
      );
    const Pricing = PricingReq(offer, Metadata, dataSet, flightList);

    const response = await getNDCAirPricing(Pricing, setLoading);

    const fees = {
      serviceCharge: serviceCharge,
      NDC_SC: NDC_SC,
    };

    if (response?.DataLists) {
      navigate(`/ndc-pricing?${query}`, {
        state: { data: { response, oneWayLength, queryURL: query, fees } },
      });
    }

    if (response?.Errors) {
      toast.error(response.Errors.Error[0].value);
      return;
    }
  };

  return (
    <div className="flex flex-col gap-6 ">
      {dataSet && (
        <Fragment>
          {/* <h1 className="text-2xl font-bold mx-2">NDC</h1> */}
          {dataSet.Errors && !dataSet.DataLists
            ? dataSet.Errors?.Error?.map((error, index) => (
                <div key={index} className="text-red-500 mx-2">
                  * {error.value}
                </div>
              ))
            : flight &&
              Object?.keys(flight)?.map((reference, index) => {
                const offers = flight[reference];
                let segment = groupDataListByReference(reference, dataSet);
                let oneWayLength =
                  offers?.[0]?.PricedOffer?.Associations[0].ApplicableFlight
                    .FlightSegmentReference.length;

                return (
                  <div key={index} onClick={() => setSelectedOffer(reference)}>
                    <ShoppingItems
                      offer={offers}
                      segment={segment}
                      oneWayLength={oneWayLength}
                      dataList={dataSet?.DataLists}
                      isSelected={selectedOffer === reference}
                      onSelect={onSelect}
                      company={company}
                      corporate={corporate}
                      NDC_SC={
                        getNDCSC(
                          offers[0],
                          company?.NDCServiceCharge,
                          totalPassengers
                        ) ||
                        getNDCTotalPrice(
                          offers[0],
                          user,
                          totalPassengers,
                          company?.NDCServiceCharge
                        ) ||
                        0
                      }
                      discount={discount}
                    />
                  </div>
                );
              })}
        </Fragment>
      )}
    </div>
  );
};

export default Shopping;
