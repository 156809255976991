import { useSelector } from "react-redux";
import FlightSearchForm from "./SearchForm";
import SimpleImageSlider from "react-simple-image-slider";
// images
import banner1 from "../../images/banners/banner1.png";
import banner2 from "../../images/banners/banner2.png";
import banner3 from "../../images/banners/banner3.jpeg";
import banner4 from "../../images/banners/banner4.png";
import banner5 from "../../images/banners/banner5.png";
import {
  USER_ROLE_FINANCE,
  USER_ROLE_SUPERAGENT,
} from "../../common/constants";

const FindFlights = () => {
  const { user } = useSelector((state) => state.auth);

  if (user?.role === USER_ROLE_FINANCE) {
    window.location.href = "/booking";
  }

  const images = [
    {
      url: "https://www.southportandormskirk.nhs.uk/wp-content/themes/southportandormskirk/assets/images/SO%20Hospitals%20Charity_Banner%20Click%20Through_V1-01.jpg",
    },
    { url: banner1 },
    { url: banner2 },
    { url: banner3 },
    { url: banner4 },
    { url: banner5 },
  ];

  return (
    <>
      <div
        className={`nc-ListingFlightsPage relative overflow-hidden bg-neutral-50 -mt-4`}
        data-nc-id="ListingFlightsPage"
      >
        <div className="relative ">
          <div className="z-99">
            <FlightSearchForm
              height={
                user?.role === USER_ROLE_SUPERAGENT ? "h-[95dvh]" : "h-[87dvh]"
              }
            />
            {user?.role !== USER_ROLE_SUPERAGENT && (
              <div className="my-1">
                <SimpleImageSlider
                  className="w-full h-[100px] lg:h-[280px] mt-1"
                  width="100%"
                  height={130}
                  images={images}
                  slideDuration={0.5}
                  autoPlay={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FindFlights;
