import {
  getAugmentationPrice,
  getFareType,
  getMetadateResponse,
  getOfferItemID,
  getSegment,
  getTravelerObjectKey,
} from "../helpers";

export const PricingReq = (offer, Metadata, dataSet, flightList) => {
  let travelerList = [];
  offer?.PricedOffer?.OfferPrice?.map((offer) => {
    offer?.RequestedDate?.Associations?.[0].AssociatedTraveler.TravelerReferences.map(
      (traveler) => {
        travelerList.push(traveler);
      }
    );
  });

  let fareComponent = [];
  offer?.PricedOffer?.OfferPrice?.map((offer) => {
    offer.FareDetail.FareComponent.map((fare) => {
      if (!fareComponent.includes(fare.refs[0])) {
        fareComponent.push(fare.refs[0]);
      }
    });
  });

  let offerItems = [];
  offer?.PricedOffer?.OfferPrice?.map((offer) => {
    offerItems.push(offer.OfferItemID);
  });

  const getClassServiceRef = (ref) => {
    if (Array.isArray(ref)) {
      return ref.map((item) => item?.ListKey);
    }
  };

  function removeFare(data) {
    return data?.map((fare) => {
      const { Fare, ...rest } = fare;
      return rest;
    });
  }

  // let segmentList = segment?.map((item, index) => {
  //   return {
  //     ...item,
  //     ClassOfService: {
  //       Code: offer.PricedOffer.Associations[0].ApplicableFlight
  //         .FlightSegmentReference[index]?.ClassOfService?.Code,
  //       refs: getClassServiceRef(getFareType(fareComponent)),
  //     },
  //     CabinType: {
  //       Code: offer.PricedOffer.Associations[0].ApplicableFlight
  //         .FlightSegmentReference[index]?.ClassOfService?.MarketingName
  //         .CabinDesignator,
  //       Name: offer.PricedOffer.Associations[0].ApplicableFlight
  //         .FlightSegmentReference[index]?.ClassOfService?.MarketingName
  //         ?.value,
  //     },
  //   };
  // });

  let segmentList = offer?.PricedOffer?.Associations?.flatMap((item) => {
    return item.ApplicableFlight.FlightSegmentReference?.map((segment) => {
      let serviceCode = segment.ClassOfService.Code;
      let cabinDesignator =
        segment.ClassOfService.MarketingName.CabinDesignator;
      let cabinName = segment.ClassOfService.MarketingName.value;

      return {
        ...getSegment(segment.ref, dataSet),
        ClassOfService: {
          Code: serviceCode,
          refs: getClassServiceRef(getFareType(fareComponent, dataSet)),
        },
        CabinType: {
          Code: cabinDesignator,
          Name: cabinName,
        },
      };
    });
  });

  flightList = flightList?.flat();

  // offer.refs[0].Ref &&
  //   priceMetaData.push(getAugmentationPrice(offer.refs[0].Ref));
  // getFareType(
  //   offer.PricedOffer.OfferPrice[0].FareDetail.FareComponent[0].refs,
  // )[0]?.refs?.[0] &&
  //   priceMetaData.push(
  //     getAugmentationPrice(
  //       getFareType(
  //         offer.PricedOffer.OfferPrice[0].FareDetail.FareComponent[0].refs,
  //       )?.[0].refs[0],
  //     ),
  //   );

  const priceMetaData = [];
  // offer.refs[0].Ref &&
  //   priceMetaData.push(getAugmentationPrice(offer.refs[0].Ref, Metadata));

  offer?.refs?.map((ref) => {
    if (ref.Ref) {
      priceMetaData.push(getAugmentationPrice(ref.Ref, Metadata));
    }
  });

  getFareType(fareComponent, dataSet)?.map((fareType, i) => {
    console.log({ Metadata });
    if (fareType?.refs?.[i]) {
      priceMetaData.push(getAugmentationPrice(fareType?.refs[0], Metadata));
    }
  });

  const createTravelerArray = (passenger) =>
    passenger.map((item) => ({
      PTC: item.PTC,
    }));

  const travelers = [
    ...createTravelerArray(getTravelerObjectKey(travelerList, dataSet)),
  ].map((traveler) => ({ AnonymousTraveler: [traveler] }));

  return {
    DataLists: {
      FareList: {
        FareGroup: removeFare(getFareType(fareComponent, dataSet)),
      },
      AnonymousTravelerList: {
        AnonymousTraveler: getTravelerObjectKey(travelerList, dataSet),
      },
    },
    Query: {
      // OriginDestination: [
      //   {
      //     Flight: segmentList,
      //   },
      // ],
      OriginDestination: offer?.PricedOffer?.Associations?.flatMap((item) => {
        return {
          Flight: item.ApplicableFlight.FlightSegmentReference?.map(
            (segment) => {
              return {
                ...getSegment(segment.ref, dataSet),
                ClassOfService: {
                  Code: segment.ClassOfService.Code,
                  refs: getClassServiceRef(getFareType(fareComponent, dataSet)),
                },
                CabinType: {
                  Code: segment.ClassOfService.MarketingName.CabinDesignator,
                  Name: segment.ClassOfService.MarketingName.value,
                },
              };
            }
          ),
        };
      }),
      Offers: {
        Offer: [
          {
            OfferID: offer?.OfferID,
            refs: offer?.refs,
            OfferItemIDs: {
              OfferItemID: getOfferItemID(offer?.PricedOffer?.OfferPrice),
            },
            // OfferItemIDs: {
            //   OfferItemID: [
            //     {
            //       refs: offer.PricedOffer.OfferPrice[0].RequestedDate
            //         .Associations[0].AssociatedTraveler.TravelerReferences,
            //       value: offer.PricedOffer.OfferPrice[0].OfferItemID,
            //     },
            //   ],
            // },
          },
        ],
      },
    },
    Travelers: {
      Traveler: travelers,
    },
    ShoppingResponseID: {
      Owner: offer?.OfferID?.Owner,
      ResponseID: {
        value: getMetadateResponse(offer?.OfferID?.Owner, Metadata)?.Key,
      },
    },
    Metadata: {
      Other: {
        OtherMetadata: [
          {
            PriceMetadatas: {
              PriceMetadata: priceMetaData,
            },
          },
        ],
      },
    },
  };
};

export const ApplicationCode = [
  {
    key: "1",
    title: "After departure No show",
  },
  {
    key: "2",
    title: "Prior to departure",
  },
  {
    key: "3",
    title: "After departure",
  },
  {
    key: "4",
    title: "Before departure No show",
  },
];
