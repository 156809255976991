import { flightSearchList } from "./flightSearchList";
import Locals from "./localFlights";
import { convertToNumber } from "./convertToNumber";
import { NON_DOCUMENT_HOLDER } from "../common/constants";

import ac from "../images/flight-logo/ac.png";
import egypt from "../images/flight-logo/egypt.png";
import qatar from "../images/flight-logo/qatar.png";
import flyDubai from "../images/flight-logo/fly-dubai.png";
import flynas from "../images/flight-logo/Flynas.png";
import et from "../images/flight-logo/et-airline.png";
import sv from "../images/flight-logo/sv-airline.png";
import kq from "../images/flight-logo/kq-airline.jpg";
import tk from "../images/flight-logo/tk-airline.png";

export const getAirSegment = (segment, flights) => {
  return flights.find(
    (flight) => flight._attributes.Key === segment._attributes.Key
  )._attributes;
};

let CarrierList = [
  {
    Code: "ET",
    Name: "Ethiopian Airlines",
    Logo: et,
    // Logo: "https://www.logolynx.com/images/logolynx/d5/d55d1aa6350e1e5374c7e0b7ecb1a007.jpeg",
  },
  {
    Code: "EY",
    Name: "Etihad Airways",
    Logo: "https://www.etihad.com/etc/designs/etihad/clientlibs/images/etihad-logo.svg",
  },
  {
    Code: "EK",
    Name: "Emirates",
    Logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx4tyMV9i_3nPZh8wGNbNp2P3lpOV6KuiD96QoFXpwepq9aAG4PGNxhl8hq4iuOrwGqkA&usqp=CAU",
  },
  {
    Code: "QR",
    Name: "Qatar Airways",
    Logo: qatar,
  },
  {
    Code: "KQ",
    Name: "Kenya Airways",
    // Logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Kenya_Airways_Logo.svg/2560px-Kenya_Airways_Logo.svg.png",
    Logo: kq,
  },
  {
    Code: "AT",
    Name: "Royal Air Maroc",
  },
  {
    Code: "TK",
    Name: "Turkish Airlines",
    Logo: tk,
  },
  {
    Code: "SU",
    Name: "Aeroflot",
  },
  {
    Code: "AF",
    Name: "Air France",
  },
  {
    Code: "BA",
    Name: "British Airways",
  },
  {
    Code: "LH",
    Name: "Lufthansa",
    Logo: "https://i.pinimg.com/originals/19/c7/b7/19c7b7a48a009b854b969f63471cb95a.png",
  },
  {
    Code: "SA",
    Name: "South African Airways",
    Logo: "https://upload.wikimedia.org/wikipedia/en/thumb/7/7c/SAA_logo_%282019%29.svg/1200px-SAA_logo_%282019%29.svg.png",
  },
  {
    Code: "SN",
    Name: "Brussels Airlines",
  },
  {
    Code: "TG",
    Name: "Thai Airways",
  },
  {
    Code: "UA",
    Name: "United Airlines",
  },
  {
    Code: "KU",
    Name: "Kuwait Airways",
    Logo: "https://upload.wikimedia.org/wikipedia/en/thumb/0/08/Kuwait_Airways_logo.svg/1200px-Kuwait_Airways_logo.svg.png",
  },
  {
    Code: "MS",
    Name: "EgyptAir",
    Logo: egypt,
    // Logo: "https://seeklogo.com/images/E/EGYPTAIR-logo-33A4261781-seeklogo.com.png",
  },
  {
    Code: "SV",
    Name: "Saudi Arabian Airlines",
    // Logo: "https://www.saudia.com/-/media/SaudiaWebApp/data/media/img/Logo/footer/saudia_logo_light_footer.ashx?h=160&iar=0&w=540&rev=ba91c75d3917459385868f6837c0aabc&hash=2AC42B58257FE5904C84288BCD52ADA8",
    Logo: sv,
  },
  {
    Code: "KL",
    Name: "KLM",
  },
  {
    Code: "FZ",
    Name: "Fly Dubai",
    Logo: flyDubai,
  },
  {
    Code: "XY",
    Name: "Flynas",
    Logo: flynas,
  },
  {
    Code: "AC",
    Name: "Air Canada",
    Logo: ac,
  },
];

export const getCarrierLogo = (code) => {
  return CarrierList.find((carrier) => carrier.Code === code)?.Logo;
};

export const getCarrierInfo = (code) => {
  return CarrierList.find((carrier) => carrier.Code === code);
};

export const getFlightName = (code) => {
  return flightSearchList(code)?.filter((flight) => {
    return flight?.code === code;
  })?.[0]?.city;
};

export const showUSDInstruction = (carrier, origin, currency, type) => {
  if (!Locals.includes(origin) && (carrier === "KQ" || carrier === "SV")) {
    return "Payment should be made in USD";
  }

  // if (carrier === "ET" || currency === "USD" || type !== NON_DOCUMENT_HOLDER) {
  //   return "Payment should be made in ETB";
  // }

  if (type === NON_DOCUMENT_HOLDER && currency !== "USD") {
    return "Payment should be made in USD";
  }
  return "";
};

export const USDTotalPrice = (carrier, price, exchangeRate, charge = 0) => {
  if (carrier !== "ET") {
    return +price / exchangeRate + charge / exchangeRate;
  }
  return +price + charge;
};

export const USDBasePrice = (carrier, price, exchangeRate) => {
  if (carrier !== "ET") {
    price = price?.toString().slice(3);
    return Math.round(+price / exchangeRate);
  }
  return convertToNumber(price);
};

export const showCurrency = (carrier) => {
  if (carrier === "ET") {
    return "ETB";
  }
  return "USD";
};

export const currencyType = (currency) => {
  if (currency === "ETB" || currency === "INR") {
    return "ETB";
  }
  // if (currency === "INR") {
  //   return "INR";
  // }
  return "USD";
};
