import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchIcon } from "@heroicons/react/solid";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import { getCompanies } from "../../../redux2/user/userSlice";
import Loading from "../../../components/Loading";
import {
  createDiscount,
  getDiscountByDestination,
} from "../../../redux2/discount/discountSlice";

const DiscountModal = ({ show, setOpen }) => {
  const { companies, isLoading } = useSelector((state) => state.user);
  const { discount, isLoading: discountLoading } = useSelector(
    (state) => state.discount
  );

  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [form, setForm] = useState({
    serviceCharge: discount?.serviceCharge || "",
    destination: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (form.destination) {
      dispatch(getDiscountByDestination(form.destination));
    }
  }, [dispatch, form.destination]);

  useEffect(() => {
    if (companies && !isLoading) {
      setFilteredCompanies(companies);
    }
  }, [companies, isLoading]);

  useEffect(() => {
    if (discount) {
      setForm({
        serviceCharge: discount?.serviceCharge || "",
        destination: discount?.destination || "",
      });
      setSelectedCompanies((prevSelected) => [
        ...new Set([...prevSelected, ...(discount?.companyIds || [])]),
      ]);
    } else {
      setForm({
        serviceCharge: "",
      });
      setSelectedCompanies([]);
    }
  }, [discount]);

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectedCompanies(isChecked ? companies?.map((c) => c._id) : []);
  };

  const handleCompanySelect = (e, company) => {
    const isChecked = e.target.checked;
    setSelectedCompanies((prevSelected) =>
      isChecked
        ? [...prevSelected, company?._id]
        : prevSelected.filter((c) => c !== company?._id)
    );
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = companies.filter((company) =>
      company.name.toLowerCase().includes(value)
    );
    setFilteredCompanies(filtered);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.serviceCharge === "" || form.destination === "") {
      toast.error("Please fill all fields");
      return;
    }

    const data = {
      companyIds: selectedCompanies,
      serviceCharge: +form.serviceCharge,
      destination: form.destination,
    };

    dispatch(createDiscount(data));
    setForm({
      serviceCharge: "",
      destination: "",
    });
    setSelectedCompanies([]);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({
      serviceCharge: "",
      destination: "",
    });
    setSelectedCompanies([]);
  };

  if (isLoading) return <Loading />;

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-right"
    >
      <Modal.Body>
        <div className="max-w-3xl w-full mx-auto bg-white rounded-lg p-6">
          <h5 className="text-3xl font-semibold mb-6 text-center text-gray-800">
            Select Agencies for Discount
          </h5>
          <form onSubmit={handleSubmit}>
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center w-full">
                <input
                  type="checkbox"
                  id="select-all"
                  className="form-checkbox h-5 w-5 text-indigo-600 transition duration-200 ease-in-out focus:ring-indigo-500"
                  onChange={handleSelectAll}
                />
                <label
                  htmlFor="select-all"
                  className="ml-3 text-lg font-medium text-gray-700 cursor-pointer mt-2"
                >
                  Select All
                </label>
              </div>

              <div className="relative w-full max-w-sm text-end">
                <input
                  type="text"
                  placeholder="Search agencies..."
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 text-gray-700 text-sm"
                  onChange={handleSearch}
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-gray-400" />
                </div>
              </div>
            </div>

            <div className="space-y-4">
              {filteredCompanies.map((company, index) => (
                <div
                  className="flex items-center bg-gray-50 hover:bg-gray-100 p-4 rounded-lg shadow-sm transition duration-200"
                  key={company._id}
                >
                  <span className="text-gray-600 text-sm font-medium mr-4">
                    {index + 1}.
                  </span>
                  <label
                    htmlFor={`company-list-${company._id}`}
                    className="flex justify-between w-full cursor-pointer"
                  >
                    <span className="text-gray-800 text-base font-medium">
                      {company.name}
                    </span>
                    <input
                      type="checkbox"
                      id={`company-list-${company._id}`}
                      className="form-checkbox h-5 w-5 text-indigo-600 transition duration-200"
                      checked={selectedCompanies.some((c) => c === company._id)}
                      onChange={(e) => handleCompanySelect(e, company)}
                    />
                  </label>
                </div>
              ))}
            </div>
            <div className="flex flex-row mt-4 gap-4">
              <select
                className="w-1/2 block border text-black text-lg border-black p-2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white"
                name="destination"
                id="destination"
                placeholder="Destination"
                onWheel={(e) => e.target.blur()}
                onChange={handleChange}
                value={form.destination}
                required
              >
                <option value="">Select Destination</option>
                <option value="DXB">DXB</option>
                <option value="ADD">ADD</option>
                <option value="JED">JED</option>
                <option value="BAH">BAH</option>
                <option value="RUH">RUH</option>
                <option value="BEY">BEY</option>
              </select>
              <input
                type="number"
                id="serviceCharge"
                className="w-1/2 block border text-black text-lg border-black p-2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white"
                onWheel={(e) => e.target.blur()}
                name="serviceCharge"
                placeholder="Discount Service Charge"
                onChange={handleChange}
                value={form.serviceCharge}
                required
              />
            </div>
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end w-full gap-4">
          <button
            type="button"
            className="px-5 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-200"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-5 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
            onClick={handleSubmit}
            disabled={discountLoading}
          >
            Submit
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DiscountModal;
