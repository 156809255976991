import React, { useState } from "react";
import countryList from "country-list";
import { Button } from "@mui/material";
import { Tab } from "@headlessui/react";

import {
  FOREIGN_PASSENGER,
  USER_ROLE_SUPERAGENT,
} from "../../../common/constants";
import Input from "../../Input/Input";
import Label from "../../Label/Label";

const PassengerForm = ({
  index,
  type,
  AirSegment,
  formData,
  onChange,
  passportData,
  setPassportData,
  onPassportChange,
  passengerTypeState,
  isLocal,
  reservedSeats,
  user,
  handleAcceptedFiles,
  warning,
  setSeatIndex,
  setSeatModal,
  isRequired,
}) => {
  const [ssrCollapsed, setSsrCollapsed] = useState(true);
  const [loyaltyCollapsed, setLoyaltyCollapsed] = useState(true);

  const onSSRChange = (e, index, i) => {
    const { value } = e.target;
    const newPassportData = [...passportData];

    if (!newPassportData[index]) {
      newPassportData[index] = {
        issueDate: "",
        expiryDate: "",
        issueCountry: "",
        ssr: [],
      };
    }
    if (!newPassportData[index].ssr) {
      newPassportData[index].ssr = [];
    }
    newPassportData[index].ssr[i] = value;
    setPassportData(newPassportData);
  };

  const getMinDate = (type) => {
    const today = new Date();
    let minDate;

    if (type === "INF") {
      minDate = new Date(
        today.getFullYear() - 2,
        today.getMonth(),
        today.getDate()
      );
    } else if (type === "CNN") {
      minDate = new Date(
        today.getFullYear() - 12,
        today.getMonth(),
        today.getDate()
      );
    } else {
      return "";
    }

    return minDate.toISOString().split("T")[0];
  };

  const getMaxDate = (type) => {
    const today = new Date();
    let maxDate;

    if (type === "ADT") {
      maxDate = new Date(
        today.getFullYear() - 12,
        today.getMonth(),
        today.getDate()
      );
    } else {
      maxDate = new Date();
    }

    return maxDate.toISOString().split("T")[0];
  };

  return (
    <div className="mt-6">
      <Tab.Group>
        <Tab.Panels>
          <Tab.Panel className=" text-lg">
            <div className="row">
              <div className="col-md-6 my-2">
                <div className="space-y-0">
                  <div className="flex flex-row gap-3">
                    <div className="flex flex-col ">
                      <Label>Title</Label>
                      <div className="flex flex-row gap-1">
                        <select
                          className="block border text-black text-[15px] border-black w-20 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white h-10 mt-1"
                          style={{
                            display: type !== "ADT" && type !== "LBR" && "none",
                          }}
                          name="Prefix"
                          value={formData[index]?.Prefix || ""}
                          onChange={(e) => onChange(e, index)}
                        >
                          <option value="" selected></option>
                          <option
                            value="Mr"
                            onChange={(e) => onChange(e, index)}
                          >
                            Mr.
                          </option>
                          <option
                            value="Mrs"
                            onChange={(e) => onChange(e, index)}
                          >
                            Mrs.
                          </option>
                          <option
                            value="Ms"
                            onChange={(e) => onChange(e, index)}
                          >
                            Ms.
                          </option>
                          <option
                            value="Dr"
                            onChange={(e) => onChange(e, index)}
                          >
                            Dr.
                          </option>
                          <option
                            value="Prof"
                            onChange={(e) => onChange(e, index)}
                          >
                            Prof.
                          </option>
                          <option
                            value="Amb"
                            onChange={(e) => onChange(e, index)}
                          >
                            Amb.
                          </option>
                        </select>
                      </div>
                    </div>

                    <div className="flex flex-col gap-1 w-full">
                      <Label>
                        First Name <span className="py-1 text-red-500">*</span>
                      </Label>
                      <Input
                        placeholder="Enter first name"
                        className="w-full"
                        name="FirstName"
                        value={formData[index]?.FirstName || ""}
                        onChange={(e) => onChange(e, index)}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 my-2">
                <div className="space-y-0">
                  <Label>Middle Name </Label>
                  <Input
                    // className="w-1/2"
                    placeholder="Enter middle name"
                    name="MiddleName"
                    value={formData[index]?.MiddleName || ""}
                    onChange={(e) => onChange(e, index)}
                  />
                  {/* </div> */}
                </div>
              </div>

              <div className="col-md-6 my-2">
                <div className="space-y-0">
                  <Label>
                    Last Name <span className="py-1 text-red-500">*</span>
                  </Label>
                  <Input
                    // className="w-1/2"
                    placeholder="Enter last name"
                    name="LastName"
                    value={formData[index]?.LastName || ""}
                    onChange={(e) => onChange(e, index)}
                    required={true}
                    // required={isLocal === "true" ? false : true}
                  />
                  {/* </div> */}
                </div>
              </div>
              <div
                className="col-md-6 my-2"
                style={{
                  display: type !== "ADT" && type !== "LBR" && "none",
                }}
              >
                <Label>
                  Email <span className="py-1 text-red-500">*</span>
                </Label>
                <Input
                  // className="w-1/2"
                  placeholder="Enter email"
                  value={formData[index]?.Email || ""}
                  onChange={(e) => onChange(e, index)}
                  name="Email"
                  required={type === "ADT"}
                  type="email"
                />
              </div>

              <div
                className="col-md-6 my-2"
                style={{
                  display: type !== "ADT" && type !== "LBR" && "none",
                }}
              >
                <div className="space-y-0">
                  <Label>
                    Phone Number <span className="py-1 text-red-500">*</span>
                  </Label>
                  <div className="flex flex-row">
                    <select
                      className="block border text-black text-[15px] border-black w-20 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white"
                      name="AreaCode"
                      value={formData[index]?.AreaCode || ""}
                      onChange={(e) => onChange(e, index)}
                    >
                      <option>+251</option>
                      <option>+1</option>
                      <option>+40</option>
                      <option>+97</option>
                    </select>
                    {/* <Input defaultValue="+251" className="w-24" /> */}
                    <Input
                      defaultValue="900000000"
                      placeholder="Enter phone number"
                      // className="w-1/3"
                      type="Number"
                      name="phone"
                      value={formData[index]?.phone || ""}
                      onChange={(e) => onChange(e, index)}
                      required={type === "ADT"}
                      minLength={9}
                      maxLength={9}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="space-y-0">
                  <Label>
                    Nationality <span className="py-1 text-red-500">*</span>
                  </Label>
                  <select
                    defaultValue="Ethiopia"
                    className="block border text-black text-[15px] border-black w-1/2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white p-2"
                    name="Nationality"
                    onChange={(e) => onChange(e, index)}
                  >
                    {countryList.getNames().map((country) => (
                      <option
                        selected={country === "Ethiopia"}
                        defaultValue="Ethiopia"
                        key={country}
                        value={country}
                      >
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="flex-1 space-y-0">
                  <Label>
                    Date of birth <span className="py-1 text-red-500">*</span>
                  </Label>
                  <Input
                    type="date"
                    // className="w-1/2"
                    name="DOB"
                    value={formData[index]?.DOB || ""}
                    onChange={(e) => onChange(e, index)}
                    required={true}
                    min={getMinDate(type)}
                    max={getMaxDate(type)}
                    // add validation for age if type is CNN age between 2-11 and if type is INF age between 0-2
                  />
                </div>
              </div>
              <div className="col-md-6 my-2">
                <div className="space-y-0">
                  <Label>
                    Gender <span className="py-1 text-red-500">*</span>
                  </Label>
                  <select
                    className="block border text-black text-[15px] border-black w-1/2 rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white p-2"
                    name="Gender"
                    onChange={(e) => onChange(e, index)}
                  >
                    <option
                      value="M"
                      selected
                      onChange={(e) => onChange(e, index)}
                    >
                      Male
                    </option>
                    <option value="F">Female</option>
                  </select>
                </div>
              </div>
              {passengerTypeState === FOREIGN_PASSENGER && (
                <div className="col-md-6 my-2">
                  <div className="space-y-0">
                    <Label>
                      Origin ID NO.
                      <p className="text-red-500/80 text-xs">
                        Note: Please note that if your customer is not an
                        Ethiopian national and has an Ethiopian origin ID,
                        please insert the ID number in the box provided.
                      </p>{" "}
                    </Label>
                  </div>
                  <Input
                    placeholder="Ethiopian Origin ID No."
                    name="OriginId"
                    value={formData[index]?.OriginId}
                    onChange={(e) => onChange(e, index)}
                  />
                </div>
              )}
              {type !== "INF" && (
                <>
                  <div className="space-y-0">
                    <Label>Choose Seat </Label>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <Button
                        className="bg-black text-white mx-3 border px-5  py-2 rounded-lg align-middle items-center"
                        onClick={() => {
                          setSeatIndex(index);
                          setSeatModal(true);
                        }}
                      >
                        Choose Seat
                      </Button>
                    </div>

                    <div className="col-md-8">
                      {/* <Label className="text-xs">Selected Seat </Label> */}
                      {reservedSeats.length > 0 && (
                        <div className="flex flex-wrap justify-between">
                          {reservedSeats?.[index]?.map((seat) => (
                            <div className="text-sky-600 font-light text-sm">
                              {/* add index of 0 at seat on 3 of them */}
                              <p>{seat?.[0]?._attributes?.SeatCode}</p>
                              {seat?.[0]?.Characteristic instanceof Array ? (
                                seat?.[0]?.Characteristic?.map(
                                  (characteristic, index) => {
                                    return (
                                      <p key={index} className="">
                                        {"*"} {characteristic._attributes.Value}
                                      </p>
                                    );
                                  }
                                )
                              ) : (
                                <p className="">
                                  {"*"}{" "}
                                  {
                                    seat?.[0]?.Characteristic?._attributes
                                      ?.Value
                                  }
                                </p>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {(AirSegment.Carrier || AirSegment[0]._attributes.Carrier) ===
                "TK" && (
                <div className="space-y-2 my-4">
                  <h1 className="font-bold text-black text-2xl">
                    Passenger Destination Address
                  </h1>

                  <div className="row border-1 border-slate-400 p-2 rounded-md">
                    <h6 className="text-red-500 text-base">
                      * All Fields are Mandatory for <b>Turkish Airlines</b>{" "}
                    </h6>
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>Address Name </Label>
                      </div>
                      <Input
                        placeholder="Address Name"
                        // className="w-1/2"
                        name="AddressName"
                        value={passportData.addressName}
                        onChange={(e) => onChange(e, index)}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="space-y-0">
                        <Label>Street</Label>
                        <Input
                          type="text"
                          placeholder="Street"
                          name="Street"
                          value={passportData.street}
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="space-y-0">
                        <Label>City</Label>
                        <Input
                          type="text"
                          placeholder="City"
                          name="City"
                          value={passportData.city}
                          onChange={(e) => onChange(e, index)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>State</Label>
                      </div>
                      <Input
                        type="text"
                        name="State"
                        placeholder="State"
                        onChange={(e) => onChange(e, index)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>Postal Code </Label>
                      </div>
                      <Input
                        type="text"
                        name="PostalCode"
                        placeholder="Postal Code"
                        onChange={(e) => onChange(e, index)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>Country Code </Label>
                      </div>
                      <Input
                        type="text"
                        placeholder="Country"
                        name="Country"
                        onChange={(e) => onChange(e, index)}
                        // className="w-1/2"
                      />
                      <p className="text-sm">
                        (Eg. insert "FR" - if your destination city is in
                        France)
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="space-y-2">
                <h1 className="font-bold text-black text-2xl">
                  Passport Details
                </h1>
                {/* show warring */}
                {warning && (
                  <p className="text-red-500 text-sm border border-red-500 rounded-md px-3 py-2 my-2">
                    <i className="fas fa-exclamation-triangle mr-2"></i>
                    Passport is about to expire. or check the passport expiry
                    date input again.
                  </p>
                )}
                <div className="row border-1 border-slate-400 p-2 rounded-md">
                  <div className="col-md-6 my-2">
                    <div className="space-y-0">
                      <Label>Passport Number </Label>
                    </div>
                    <Input
                      placeholder="Passport Number"
                      // className="w-1/2"
                      name="DocumentNumber"
                      value={passportData?.[index]?.DocumentNumber || ""}
                      onChange={(e) => onPassportChange(e, index)}
                      required={isLocal === "true" ? false : true}
                    />
                  </div>
                  {/* <div className="col-md-6"></div> */}
                  {/* <div className="col-md-6">
                      <div className="space-y-0">
                        <Label>Passport Issued Date</Label>
                        <Input
                          type="date"
                          // className="w-1/2"
                          required={isLocal === "true" ? false : true}
                          name="issueDate"
                          value={passportData?.[index]?.issueDate || ""}
                          onChange={(e) => onPassportChange(e, index)}
                          // min date before today
                          max={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </div> */}
                  <div className="col-md-6 my-2">
                    <div className="space-y-0">
                      <Label>Passport Expire Date</Label>
                      <Input
                        type="date"
                        // className="w-1/2"
                        required={isLocal === "true" ? false : true}
                        name="expiryDate"
                        value={passportData?.[index]?.expiryDate || ""}
                        onChange={(e) => onPassportChange(e, index)}
                      />
                    </div>
                  </div>
                  {isLocal === "false" && (
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label> Upload Passport </Label>
                      </div>
                      <input
                        // accept="image/*"
                        accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                        // style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        name="passport"
                        onChange={(e) => handleAcceptedFiles(e, index)}
                        // required={isRequired(isLocal, user, index)}

                        // onChange={(e) => onPassportChange(e, index)}
                        // className="w-1/2"
                      />
                      <Label className="text-gray-600">
                        Upload a file with Max size of 5MB
                      </Label>
                      {user?.role !== USER_ROLE_SUPERAGENT && (
                        <p className="text-sm text-red-500">
                          * Please upload passport.
                        </p>
                      )}
                    </div>
                  )}

                  {isLocal === "true" && (
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label>
                          {passengerTypeState === "Foreigner"
                            ? "Upload passport"
                            : "Upload passport/Kebele Card(ID)"}
                        </Label>
                      </div>
                      <input
                        accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                        // style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        name="idCard"
                        onChange={(e) => handleAcceptedFiles(e, index)}
                        // required={isRequired(isLocal, user, index)}
                        // className="w-1/2"
                      />
                      <Label className="text-gray-700">
                        Upload a file with Max size of 5MB
                      </Label>
                      {passengerTypeState !== "Foreigner" && (
                        <p className="text-sm text-red-500">
                          * Please upload passport, Kebele ID, Driving License
                          or Work ID
                        </p>
                      )}
                    </div>
                  )}
                  {/* {type === "LBR" && (
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label> Labor Id </Label>
                        </div>
                        <input
                          accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                          // style={{ display: "none" }}
                          id="raised-button-file"
                          type="file"
                          name="certificate"
                          onChange={(e) => handleAcceptedFiles(e, index)}
                          required={type === "LBR"}
                          // className="w-1/2"
                        />
                        <Label className="text-gray-700">
                          Upload a file with Max size of 5MB
                        </Label>
                      </div>
                    )} */}

                  {passengerTypeState === "Foreigner" && (
                    <div className="col-md-6 my-2">
                      <div className="space-y-0">
                        <Label> Other Uploads </Label>
                      </div>
                      <input
                        accept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx .img, .png, .jpg, .jpeg"
                        // style={{ display: "none" }}
                        id="raised-button-file"
                        type="file"
                        name="other"
                        onChange={(e) => handleAcceptedFiles(e, index)}
                        required={passengerTypeState === "Foreigner"}
                        // className="w-1/2"
                      />
                      <Label className="text-gray-700">
                        Upload a file with Max size of 5MB
                      </Label>
                      <p className="text-sm text-red-500">
                        * Please upload Yellow Card, Residential Permit, Work
                        Permit or Origin Id
                      </p>
                    </div>
                  )}

                  <div className="space-y-2">
                    <div className="flex flex-row justify-between py-3">
                      <h1 className="font-semibold text-black text-xl pt-2">
                        Special Requests
                      </h1>

                      <button
                        onClick={() => {
                          setSsrCollapsed(!ssrCollapsed);
                        }}
                        type="button"
                      >
                        <i
                          className={
                            "text-xl text-main font-bold pt-2 " +
                            (ssrCollapsed ? "fa fa-plus" : "fa fa-minus")
                          }
                        ></i>
                      </button>
                    </div>
                    <div
                      className={
                        ssrCollapsed
                          ? "hidden row border-1 border-slate-200 p-2 rounded-md"
                          : "visible row border-1 border-slate-200 p-2 rounded-md"
                      }
                      id="collapseSSR"
                    >
                      {type !== "INF" && (
                        <div className="col-md-6 ">
                          <div className="space-y-0">
                            <Label>Meal Preference </Label>
                            <select
                              className="block border p-2 text-black text-[15px] border-black rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white w-full"
                              name="ssr"
                              value={passportData[index]?.ssr?.[0] || ""}
                              onChange={(e) => onSSRChange(e, index, 0)}
                            >
                              <option value="" selected>
                                (optional)
                              </option>
                              <option value="AVML">
                                Vegetarian Hindu meal
                              </option>
                              <option value="BBML">Baby meal</option>
                              <option value="BLML">Bland meal</option>
                              <option value="CHML">Child meal</option>
                              <option value="DBML">Diabetic meal</option>
                              <option value="FPML">Fruit platter meal</option>
                              <option value="GFML">
                                Gluten intolerant meal
                              </option>
                              <option value="HNML">Hindu meal</option>
                              <option value="KSML">Kosher meal</option>
                              <option value="LCML">Low calorie meal</option>
                              <option value="LFML">Low fat meal</option>
                              <option value="LSML">Low salt meal</option>
                              <option value="MOML">Moslem meal</option>
                              <option value="NLML">Low lactose meal</option>
                              <option value="NOML">No meal</option>
                              <option value="RVML">Vegetarian raw meal</option>
                              <option value="SFML">Sea food meal</option>
                              <option value="VGML">
                                Vegetarian vegan meal
                              </option>
                              <option value="VJML">Vegetarian Jain meal</option>
                              <option value="VLML">
                                Vegetarian meal lacto-ovo (allows milk & eggs)
                              </option>
                              <option value="VOML">
                                Vegetarian oriental meal
                              </option>
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="col-md-6">
                        <div className="space-y-0">
                          <Label>Special Requests </Label>
                          <select
                            className="block border p-2 text-black text-[15px] border-black rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white w-full"
                            name="ssr"
                            value={passportData[index]?.ssr?.[1] || ""}
                            onChange={(e) => onSSRChange(e, index, 1)}
                          >
                            <option value="" selected>
                              (optional)
                            </option>
                            <option value="BLND">Blind Passenger</option>
                            <option value="DEAF">Deaf Passenger</option>
                            {/* <option value="NZ">Meet and Assist</option> */}
                            {/* DPNA: Intellectual or development disability needing assistance */}
                            <option value="WCHR">
                              Wheelchair/Passenger can walk up/to stairs
                            </option>
                            <option value="WCHC">
                              Wheelchair/Passenger must be carried
                            </option>
                            {/* <option value="KP">Medical Case</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="flex flex-row justify-between py-3">
                      <h1 className="font-semibold text-black text-xl pt-2">
                        Loyalty Card
                      </h1>
                      <button
                        onClick={() => {
                          setLoyaltyCollapsed(!loyaltyCollapsed);
                        }}
                        type="button"
                      >
                        <i
                          className={
                            "text-xl text-main font-bold pt-2 " +
                            (loyaltyCollapsed ? "fa fa-plus" : "fa fa-minus")
                          }
                        ></i>
                      </button>
                    </div>
                    <div
                      className={
                        loyaltyCollapsed
                          ? "hidden row border-1 border-slate-200 p-2 rounded-md"
                          : "visible row border-1 border-slate-200 p-2 rounded-md"
                      }
                    >
                      <div className="col-md-6 ">
                        <div className="space-y-0">
                          <Label>Supplier Code </Label>
                          <select
                            className="block border p-2 text-black text-[15px] border-black rounded-lg focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white"
                            name="SupplierCode"
                            value={passportData[index]?.SupplierCode || ""}
                            onChange={(e) => onPassportChange(e, index)}
                          >
                            <option value="" selected>
                              Select Supplier Code
                            </option>
                            <option value="ET">ShebaMiles (ET)</option>
                            <option value="AC">Aeroplan (AC)</option>
                            <option value="NZ">Airpoints (NZ)</option>
                            <option value="NH">ANA Mileage Club (NH)</option>
                            <option value="OZ">Asiana Club (OZ)</option>
                            <option value="KP">ASKY Club (KP)</option>
                            <option value="CM">ConnectMiles (CM)</option>
                            <option value="MS">EgyptAir Plus (MS)</option>
                            <option value="SK">EuroBonus (SK)</option>
                            <option value="AI">Flying Returns (AI)</option>
                            <option value="BR">
                              Infinity MileageLands (BR)
                            </option>
                            <option value="SQ">KrisFlyer (SQ)</option>
                            <option value="AV">LifeMiles (AV)</option>
                            <option value="UA">MileagePlus (UA)</option>
                            <option value="A3">Miles & Bonus (A3)</option>
                            <option value="LH">Miles & More (LH)</option>
                            <option value="TK">Miles & Smiles (TK)</option>
                            <option value="CA">PhoenixMiles (CA)</option>
                            <option value="TG">Royal Orchid Plus (TG)</option>
                            <option value="">TAP Miles&Go</option>
                            <option value="SA">Voyager (SA)</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 my-2">
                        <div className="space-y-0">
                          <Label>Card Number </Label>
                        </div>
                        <Input
                          placeholder="(optional) "
                          // className="w-1/2"
                          name="CardNumber"
                          value={passportData[index]?.CardNumber || ""}
                          onChange={(e) => onPassportChange(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      {/*  */}
      {/* <div className="flex justify-end pb-3">
          <ButtonPrimary
            // href={"/checkout"}
            className="hover:text-white hover:bg-blue-600 block w-72 md:w-48"
            // onClick={onSubmit}
            type="submit"
          >
            Continue
          </ButtonPrimary>
        </div> */}
    </div>
  );
};

export default PassengerForm;
