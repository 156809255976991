import React, { Fragment } from "react";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Button, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";

import IssueTicket from "./IssueTicket";
import BookingDetail from "./BookingDetail";
import customTime from "../../../utils/customTime";
import CheckTTL from "../../../components/Modals/CheckTTL";
import Exchange from "../../../components/Exchange";
import ShowFareModal from "./ShowFareModal";
import { getStatusBadge } from "./bookingStatus";
import API_URL from "../../../redux2";
import { useDispatch } from "react-redux";
import Locals from "../../../utils/localFlights";
import {
  GDS,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_FINANCE,
  USER_ROLE_SUPERAGENT,
} from "../../../common/constants";
import { formatNumber } from "../../../utils/convertToNumber";
import { updateBooking } from "../../../redux2/booking/bookingSlice";
import { retrieveTicket } from "../../../services/flight";
import { openModal } from "../../../redux2/modal/modalSlice";
import {
  NDCRepriceReq,
  NDCRetrieveReq,
  NDCVoidReq,
} from "../../../components/NDC/NDCRequest/NDCRequest";
import {
  getNDCOrderRetrieve,
  repriceNDCOrder,
  voidNDCTicket,
} from "../../../components/NDC/Services/NDCApi";
import NDCPayLater from "../../../components/NDC/AirPayment/PayLater";

const BookingBody = ({
  bookings,
  currentPage,
  bookingPerPage,
  user,
  setBookingId,
  setLoading,
  setError,
  setShow,
  setBookingRequestModal,
  bookingRequestModal,
  setSelectedBooking,
  rateDifference,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const cancelSegment = async (booking) => {
    setLoading(true);
    const res = await axios.post(API_URL + "flight/cancel-segment", {
      PNR: booking?.flight?.retrievalPnr,
    });

    if (res.data?.Fault) {
      setError(res.data?.Fault?.faultstring?._text);
    } else {
      const data = {
        id: booking?._id,
        payment: "none",
        paymentStatus: "cancelled",
        status: "cancelled",
        serviceCharge: 0,
        systemFee: 0,
        PNR: "",
        RetrievalPnr: "",
      };
      dispatch(updateBooking(data));
      setTimeout(() => {
        navigate(0);
      }, 1000);
    }
    setLoading(false);
  };

  const retrievePnr = async (booking) => {
    const { flight, passengers, company } = booking;

    if (booking.channel === GDS) {
      if (!flight?.retrievalPnr) return;

      const res = await retrieveTicket(flight.retrievalPnr, setLoading);

      navigate(`/ticket/${flight?.retrievalPnr}`, {
        state: {
          ticketData: res?.UniversalRecordRetrieveRsp?.UniversalRecord,
          flightInfo: flight,
          passengerInfo: passengers,
          companyInfo: company,
        },
      });
    } else {
      const data = {
        PNR: flight?.PNR,
        carrier: flight?.carrier,
      };
      const retrieve = NDCRetrieveReq(data);

      const res = await getNDCOrderRetrieve(
        retrieve,
        flight.carrier,
        setLoading
      );

      if (res?.Errors?.Error) {
        toast.error("Could not retrieve ticket");
        return;
      }

      navigate(`/ndc-ticket/${flight?.providerPnr}`, {
        state: {
          ticketData: res?.Response,
          passengerInfo: passengers,
          companyInfo: company,
        },
      });
    }
  };

  const issueNDCTicket = async (booking) => {
    const data = {
      PNR: booking?.flight?.PNR,
      carrier: booking?.flight?.carrier,
    };

    const retrieveReq = NDCRetrieveReq(data);
    const repriceReq = NDCRepriceReq(data);

    const [retrieve, reprice] = await Promise.all([
      await getNDCOrderRetrieve(
        retrieveReq,
        booking?.flight?.carrier,
        setLoading
      ),
      await repriceNDCOrder(repriceReq, booking?.flight?.carrier, setLoading),
    ]);

    if (retrieve.Response && reprice?.response) {
      navigate(`/ndc-pay-later/${booking?.flight?.providerPnr}`, {
        state: {
          retrieve: retrieve?.Response,
          reprice: reprice?.response,
          booking: booking,
        },
      });
    }
  };

  const confirmVoid = async (booking) => {
    if (booking.channel === GDS) {
      checkPassenger(booking)
        ? setShow(true)
        : confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className="text-center w-[500px] p-10 bg-white shadow-lg text-black">
                  <h1 className="text-2xl font-bold text-red-500 p-2">
                    Confirm Ticket Void
                  </h1>
                  <p className="text-lg">
                    Are you sure you want to void the ticket?
                  </p>
                  <button
                    onClick={onClose}
                    className="w-40 border-1 cursor-pointer text-black text-lg m-2.5 p-2.5 border-black"
                  >
                    No
                  </button>
                  <button
                    onClick={() => {
                      voidTicket(booking);
                      onClose();
                    }}
                    className="w-40 border-1 cursor-pointer text-red-500 text-lg m-2.5 p-2.5 border-red-500 font-bold"
                  >
                    Yes, Void it!
                  </button>
                </div>
              );
            },
          });

      setSelectedBooking(booking);
      return;
    }
    const data = {
      PNR: booking?.flight?.PNR,
      carrier: booking?.flight?.carrier,
    };

    const voidReq = NDCVoidReq(data);

    const res = await voidNDCTicket(
      voidReq,
      booking?.flight?.carrier,
      setLoading
    );

    if (res.Errors?.Error) {
      setError(res.Errors.Error[0]?.value);
      return;
    }
    window.location.reload();
  };

  const checkTicket = async (flight) => {
    if (!flight?.retrievalPnr) return;

    const res = await retrieveTicket(flight.retrievalPnr, setLoading);

    navigate(`/check-ticket/${flight?.retrievalPnr}`, {
      state: {
        ticketData: res?.UniversalRecordRetrieveRsp?.UniversalRecord,
      },
    });
  };

  const kachaOTP = async (booking, modalType) => {
    const kachaReq = {
      phone: booking?.company?.officeNumber,
      // principal: totalPrincipal.toString(),
      // phone: "251923130324",
      principal: "100",
      code: "80000030",
      pnr_number: booking.flight.PNR,
    };

    // const data = await KachaRequestLoan({ ...kachaReq, setLoading });
    setLoading(true);
    try {
      const { data } = await axios.post(
        API_URL + "booking/payment/kacha",
        kachaReq
      );

      if (data?.initiate) {
        const modalData = {
          kacha: data?.initiate,
          id: booking?._id,
        };
        dispatch(openModal({ modalType, data: modalData }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }

    return;
  };

  const confirmCancel = async (booking) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center w-[500px] p-10 bg-white shadow-lg text-black">
            <h1 className="text-2xl font-bold text-red-500 p-2">
              Confirm Cancel Booking
            </h1>
            <p className="text-lg">
              Are you sure you want to cancel the ticket?
            </p>
            <button
              onClick={onClose}
              className="w-40 border-1 cursor-pointer text-black text-lg m-2.5 p-2.5 border-black"
            >
              No
            </button>
            <button
              onClick={() => {
                cancelSegment(booking);
                onClose();
              }}
              className="w-40 border-1 cursor-pointer text-red-500 text-lg m-2.5 p-2.5 border-red-500 font-bold"
            >
              Yes, Cancel it!
            </button>
          </div>
        );
      },
    });
  };

  const voidTicket = async (booking) => {
    if (!booking) return;

    const data = {
      ProviderLocatorCode: booking?.flight?.providerPnr,
      documentNumbers: [],
      bookingId: booking?._id,
      company: booking?.company?._id,
    };

    for (let i = 0; i < booking?.flight?.ticketNumber?.length; i++) {
      data.documentNumbers.push({
        DocumentNumber: booking?.flight?.ticketNumber?.[i],
        DocumentType: "E-Ticket",
      });
    }

    setLoading(true);
    try {
      const res = await axios.post(API_URL + "flight/void-ticket", data);

      if (
        res.data?.AirVoidDocumentRsp?.VoidResultInfo?.[0]?.FailureRemark
          ?._text ||
        res.data?.AirVoidDocumentRsp?.VoidResultInfo?.FailureRemark?._text
      ) {
        setError(
          res.data?.AirVoidDocumentRsp?.VoidResultInfo?.FailureRemark?._text
        );
        return;
      }

      if (
        res.data.AirVoidDocumentRsp?.VoidResultInfo?.[0]?._attributes
          ?.ResultType === "Success" ||
        res.data.AirVoidDocumentRsp?.VoidResultInfo?._attributes?.ResultType ===
          "Success"
      ) {
        await axios.post(API_URL + "flight/cancel-segment", {
          PNR: booking?.flight?.retrievalPnr,
        });
        toast.success("Ticket Voided Successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        navigate(0);
      }, 1000);
      setLoading(false);
    }
  };

  const handleLink = (booking) => {
    let docs = booking?.documents;

    docs.forEach((link) => {
      window.open(link, "_blank");
    });

    docs?.forEach((doc, index) => {
      fetch(doc)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // a.download = fileName.doc.split(".").pop();
          a.download =
            `${booking?.flight?.ticketNumber[index] || ""}-${
              booking?.passengers[index]?.firstName +
              " " +
              booking?.passengers[index]?.lastName
            }` +
            "." +
            doc.split(".").pop();
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        })
        .catch((error) => console.error("Download error:", error));
    });
  };

  function customDate(date) {
    let d = date?.split("T")?.[0];
    return moment(d).format("MMM DD, YYYY");
  }

  function checkBeforeMidnight(booking) {
    const issueDate = new Date(booking?.createdAt);
    const currentDate = new Date(); // Current date and time

    currentDate.setHours(0, 0, 0, 0);

    const isBeforeMidnight = issueDate >= currentDate;
    return isBeforeMidnight;
  }

  function checkPassenger(booking) {
    let isPassenger = true;
    if (booking?.passengers?.length === 1) {
      return false;
    }
    isPassenger = booking?.passengers?.every(
      (passenger) => passenger?.type === "ADT" || passenger?.type === "LBR"
    );

    return isPassenger;
  }

  function reBook(booking) {
    const { flight, passengers } = booking;

    const isLocal =
      Locals.includes(flight?.origin) && Locals.includes(flight?.destination);

    const tripType = flight?.flightType === "One Way" ? "oneWay" : "roundTrip";
    const cabinClass = booking?.channel === GDS ? flight?.cabinClass : "";

    let passengerCount = {
      ADT: 0,
      CNN: 0,
      INF: 0,
      LBR: 0,
    };

    passengers.forEach((passenger) => {
      passengerCount[passenger.type] += 1;
    });

    let queryURl = `/?origin=${flight.origin}&destination=${
      flight.destination
    }&tripType=${tripType}&cabinClass=${cabinClass}&adults=${
      passengerCount?.ADT
    }&labors=${passengerCount.LBR}&children=${passengerCount.CNN}&infants=${
      passengerCount.INF
    }&startDate=${
      flight?.routes?.[0]?.departureTime[0]?.split("T")?.[0]
    }&endDate=${
      flight?.routes?.[1]?.departureTime[0]?.split("T")?.[0]
    }&isLocal=${isLocal}&_id=${booking?._id}`;

    navigate(queryURl);
  }

  function calculateOriginalPrice(booking) {
    let originalPrice = 0;
    booking.passengers?.forEach((flight) => {
      originalPrice += flight?.price;
    });

    originalPrice =
      booking?.currency === "USD" && booking?.company
        ? (originalPrice * rateDifference).toFixed(2)
        : originalPrice;

    let charge =
      booking?.currency === "USD"
        ? booking?.USDServiceCharge || 0
        : (booking?.serviceCharge || 0) + (booking?.NDCServiceCharge || 0);

    let result = parseFloat(originalPrice) + charge;
    return formatNumber(result);
  }

  return (
    <>
      {bookings?.length === 0 ? (
        <tr>
          <td colSpan={11}>
            <Typography variant="h5">No Booking Found</Typography>
          </td>
        </tr>
      ) : (
        bookings?.map((booking, index) => (
          <tr role="row" className="odd" key={index}>
            <td>
              <p>{index + 1 + (currentPage - 1) * bookingPerPage}</p>
            </td>
            <td className="py-2 text-right">
              <Dropdown className="dropdown text-sans-serif">
                <Dropdown.Toggle
                  // id="dropdown"
                  variant=""
                  className="text-primary hover:bg-main-200 i-false tp-btn-light sharp"
                  type="button"
                  id="order-dropdown-0"
                  data-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="18px"
                      height="18px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <circle fill="#000000" cx={5} cy={12} r={2} />
                        <circle fill="#000000" cx={12} cy={12} r={2} />
                        <circle fill="#000000" cx={19} cy={12} r={2} />
                      </g>
                    </svg>
                  </span>
                </Dropdown.Toggle>

                {user.role !== USER_ROLE_FINANCE && (
                  <Dropdown.Menu
                    className="dropdown-menu dropdown-menu-right border py-0"
                    aria-labelledby="order-dropdown-0"
                  >
                    {booking?.status === "confirmed" && (
                      <>
                        <div>
                          <button
                            className="dropdown-item"
                            onClick={() => retrievePnr(booking)}
                          >
                            Get Ticket
                          </button>
                        </div>
                        {checkBeforeMidnight(booking) &&
                          booking?.channel === GDS && (
                            <div className="">
                              <button
                                className="dropdown-item"
                                onClick={() => confirmVoid(booking)}
                              >
                                Void Ticket
                              </button>
                            </div>
                          )}
                        {(user?.role === USER_ROLE_SUPERAGENT ||
                          user?.role === USER_ROLE_EMPLOYEE) && (
                          <Exchange selectedBooking={booking} />
                        )}

                        {booking?.fareRule && (
                          <ShowFareModal rule={booking?.fareRule} />
                        )}
                        {/* <MiniFareRuleModal
                        setLoading={setLoading}
                        loading={loading}
                        pnr={booking?.flight?.retrievalPnr}
                      /> */}
                      </>
                    )}
                    {booking?.status === "exchanged" && (
                      <div>
                        <button
                          className="dropdown-item"
                          onClick={() => retrievePnr(booking)}
                        >
                          Get Ticket
                        </button>
                      </div>
                    )}
                    {booking?.status === "pending" && (
                      <>
                        {booking?.channel === GDS ? (
                          <IssueTicket
                            user={user}
                            booking={booking}
                            currency={booking?.currency}
                          />
                        ) : (
                          <div className="">
                            <button
                              className="dropdown-item"
                              onClick={() => issueNDCTicket(booking)}
                            >
                              Issue Ticket
                            </button>
                          </div>
                        )}
                        {/* </button> */}
                        <div className="">
                          <button
                            className="dropdown-item"
                            onClick={() => retrievePnr(booking)}
                          >
                            View Booking
                          </button>
                        </div>

                        <div className="">
                          <button
                            className="dropdown-item"
                            onClick={() => confirmCancel(booking)}
                          >
                            Cancel Booking
                          </button>
                        </div>

                        <div className="dropdown-item">
                          <CheckTTL booking={booking} />
                        </div>
                      </>
                    )}
                    {booking?.paymentStatus === "unpaidKachaCredit" && (
                      <button
                        className="cursor-pointer dropdown-item"
                        onClick={() => kachaOTP(booking, "Kacha")}
                      >
                        Re-enter Kacha OTP
                      </button>
                    )}
                    <button
                      className="cursor-pointer dropdown-item"
                      onClick={() => reBook(booking)}
                    >
                      Re-book Flight
                    </button>
                    <div className="dropdown-item">
                      <BookingDetail booking={booking} />
                    </div>
                    <button
                      className="cursor-pointer dropdown-item"
                      onClick={() => checkTicket(booking?.flight)}
                    >
                      Check Status
                    </button>
                    {booking?.status === "bookingRequested" && (
                      <Button
                        className="dropdown-item hover:bg-main-800 border-main-100"
                        onClick={() => {
                          setBookingRequestModal(!bookingRequestModal);
                          setBookingId(booking?._id);
                        }}
                      >
                        Change Booking Request Status
                      </Button>
                    )}
                    {(booking?.status === "confirmed" ||
                      booking?.status === "exchanged") &&
                      !checkBeforeMidnight(booking) && (
                        <div className="py-2">
                          <Link
                            to={`/send-request/${booking?._id}`}
                            className="dropdown-item"
                          >
                            Send Request
                          </Link>
                        </div>
                      )}
                  </Dropdown.Menu>
                )}
              </Dropdown>
            </td>
            <td className="py-2">
              {booking?.paymentStatus === "unpaidKachaCredit"
                ? getStatusBadge(booking?.paymentStatus, true)
                : getStatusBadge(booking.status)}
            </td>

            <td>
              <div className="media-bx">
                <div>
                  <h4>
                    <div className="text-black font-semibold capitalize text-sm">
                      {booking?.passengers?.map((passenger, index) => {
                        return (
                          <Fragment key={passenger._id}>
                            <p key={passenger._id}>
                              {passenger?.firstName} {passenger?.lastName}
                            </p>
                            {index === 0 && (
                              <span className="text-xs text-gray-400">
                                +{passenger?.phoneNumber}
                              </span>
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                  </h4>
                </div>
              </div>
            </td>
            <td>
              <div className="text-main ">
                {booking?.flight?.ticketNumber.map((ticket, index) => {
                  return booking?.passengers?.[index]?.status !== "pending" &&
                    booking?.passengers?.[index]?.status !== undefined &&
                    booking.status === "confirmed" ? (
                    <p key={index} className="text-indigo-500">
                      {ticket}{" "}
                      <span className="text-xs">
                        {booking?.passengers?.[index]?.status}
                      </span>
                    </p>
                  ) : (
                    <p key={index}>{ticket}</p>
                  );
                }) || ""}{" "}
              </div>
              {/* <p className="text-main">#ET-631c3e1d29</p> */}
            </td>
            <td>
              <div className="text-main ">
                {booking?.flight?.exTicketNumber.map((ticket, index) => {
                  return <p key={index}>{ticket}</p>;
                }) || ""}{" "}
              </div>
              {/* <p className="text-main">#ET-631c3e1d29</p> */}
            </td>
            <td>
              <div>
                <h5>
                  {booking?.status === "confirmed"
                    ? moment(booking?.updatedAt).format("MMM DD, YYYY")
                    : moment(booking?.createdAt).format("MMM DD, YYYY")}
                </h5>
                <span className="fs-14">
                  {booking?.status === "confirmed"
                    ? moment(booking?.updatedAt).format("hh:mm A")
                    : moment(booking?.createdAt).format("hh:mm A")}
                </span>
              </div>
            </td>
            <td>
              <p className="text-center">{booking?.flight?.carrier}</p>
            </td>
            <td>
              <Link
                className="capitalize"
                to={`/agent-billing/${booking?.company?._id}`}
              >
                {booking?.company?.name || "Fetan Ticket"}
              </Link>
            </td>
            <td>
              <p className="capitalize">
                {booking?.agent?.firstName + " " + booking?.agent?.lastName}
              </p>
            </td>
            <td>
              <p>{booking?.corporateName}</p>
            </td>
            <td>
              <p className="text-green-600">{booking?.payment}</p>
            </td>
            <td>
              <p>
                {/* {booking?.flight?.origin} - {booking?.flight?.destination} */}
                {/* {booking?.flight?.isRoundTrip ? "Round Trip" : "One Way"} */}
                {booking?.flight?.flightType}
              </p>
            </td>
            <td>
              {booking?.flight?.routes?.map((route) => (
                <p className="w-36">
                  {route?.flights.map((flight, index) => {
                    const separator =
                      index === route?.flights.length - 1 ? "" : "-";
                    return flight + separator;
                  })}
                </p>
              ))}
              {/* <p>
                  {booking?.flight?.routes?.[1]?.flights.map(
                    (flight, index) => {
                      const separator =
                        index ===
                        booking?.flight?.routes?.[0]?.flights.length - 1
                          ? ""
                          : "-";
                      return flight + separator;
                    }
                  )}
                </p> */}
            </td>
            <td>
              <div>
                {booking?.flight?.routes?.map((flight, index) => (
                  <>
                    <h5>{customDate(flight?.departureTime?.[0])}</h5>
                    <span className="fs-14">
                      {customTime(flight?.departureTime?.[0])}
                    </span>
                  </>
                ))}
              </div>
            </td>
            <td>
              {booking?.flight?.routes?.map((flight, index) => (
                <>
                  <h5>
                    {customDate(
                      flight?.arrivalTime?.[flight?.arrivalTime?.length - 1]
                    )}
                  </h5>
                  <span className="fs-14">
                    {customTime(
                      flight?.arrivalTime?.[flight?.arrivalTime?.length - 1]
                    )}
                  </span>
                </>
              ))}
            </td>
            <td>
              <p>
                {formatNumber(booking?.totalPrice)} <br />
                {/* {booking?.flight?.price + booking?.serviceCharge || 0} */}
                <span className="mx-1 text-blue-700">ETB</span>
              </p>
            </td>
            <td>
              <p>
                {calculateOriginalPrice(booking)} <br />
                <span className="text-blue-700">
                  {booking?.currency || "ETB"}
                </span>
              </p>
            </td>
            {
              // user?.role === USER_ROLE_SUPERAGENT &&
              booking?.documents.length > 0 ? (
                <td>
                  <button
                    onClick={() => handleLink(booking)}
                    key={index}
                    className="p-1 outline rounded-md text-black bg-white hover:bg-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
                  >
                    View {booking?.documents.length} Documents
                  </button>
                </td>
              ) : (
                <td></td>
              )
            }
            <td>
              <p className="text-center">
                {/* {booking?.flight?.retrievalPnr} */}
                {booking?.flight?.providerPnr}
              </p>
            </td>
            <td>
              <p className="text-center">{booking?.paymentReference}</p>
            </td>
            <td>
              <p className="text-center">{booking?.channel}</p>
            </td>
            <td>
              <p className="text-center">{booking?.paymentStatus}</p>
              <p className="text-center text-black">{booking?.reference}</p>
            </td>
          </tr>
        ))
      )}

      {/* <KachaCreditOTP otp={otp} setOtp={setOtp} /> */}
    </>
  );
};

export default BookingBody;
