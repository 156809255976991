import { TRIP_TYPE_ONE_WAY } from "../../common/constants";
import { getServiceChargeInternational } from "../../utils/serviceChargePrice";

export const groupOffersByReference = (offers) => {
  return offers?.reduce((acc, offer) => {
    const reference = offer?.PricedOffer?.Associations?.map((flights) =>
      flights.ApplicableFlight.FlightSegmentReference?.map((Ref) => Ref.ref)
    );

    if (!acc[reference]) {
      acc[reference] = [];
    }
    acc[reference].push(offer);
    return acc;
  }, {});
};

export const collectOffersByReference = (firstOffer, offers) => {
  const storedRefs = JSON.parse(localStorage.getItem("pairedRefs") || "[]");

  const firstOfferRefs =
    firstOffer?.OffersGroup?.PricedOffer?.Associations?.flatMap((association) =>
      association.ApplicableFlight.FlightSegmentReference.map(
        (segment) => segment.ref
      )
    );

  const firstOfferKey = firstOfferRefs?.join(",");

  if (!firstOfferKey) return null;

  if (storedRefs.includes(firstOfferKey)) return null;

  // Find matching offers not yet processed
  const matchedOffers = offers.filter((offer) => {
    const offerRefs = offer?.PricedOffer?.Associations?.flatMap((association) =>
      association.ApplicableFlight.FlightSegmentReference.map(
        (segment) => segment.ref
      )
    )?.join(",");

    return offerRefs === firstOfferKey && !storedRefs.includes(offerRefs);
  });

  if (matchedOffers.length > 0) {
    storedRefs.push(firstOfferKey);
    localStorage.setItem("pairedRefs", JSON.stringify(storedRefs));
  }

  return matchedOffers.length > 0 ? { [firstOfferKey]: matchedOffers } : null;
};

export const groupDataListByReference = (reference, dataSet) => {
  let references = reference.split(",");
  let result = [];
  for (let i = 0; i < references.length; i++) {
    result = result.concat(
      dataSet?.DataLists?.FlightSegmentList?.FlightSegment.reduce(
        (acc, data) => {
          if (data.SegmentKey === references[i]) {
            acc.push(data);
          }
          return acc;
        },
        []
      )
    );
  }
  return result;
};

export const getSegment = (reference, dataSet) => {
  return dataSet?.DataLists?.FlightSegmentList?.FlightSegment?.find(
    (item) => item.SegmentKey === reference
  );
};

export const getAugmentationPrice = (ref, Metadata) => {
  return Metadata?.[0]?.PriceMetadatas?.PriceMetadata?.find(
    (item) => item.MetadataKey === ref
  );
};

export const getMetadateResponse = (ref, Metadata) => {
  return Metadata[0].DescriptionMetadatas?.DescriptionMetadata[0]?.AugmentationPoint?.AugPoint?.find(
    (item) => item.Owner === ref
  );
};

export const getClassType = (ref, dataSet) => {
  return dataSet?.DataLists?.PriceClassList?.PriceClass?.find(
    (item) => item.ObjectKey === ref
  )?.Name;
};

export const getFareType = (refs, dataSet) => {
  if (!refs || refs.length < 1) return;
  return refs?.map((ref) =>
    dataSet?.DataLists?.FareList?.FareGroup.find((item) => {
      // if (item.ListKey === ref) {
      //   console.log(item);
      //   return {
      //     FareBasisCode: item.FareBasisCode,
      //     ListKey: item.ListKey,
      //     refs: item.refs,
      //   };
      // }
      return item.ListKey === ref;
    })
  );
};

export const getTravelerObjectKey = (refs, dataSet) => {
  if (Array.isArray(refs)) {
    return refs.map((ref) =>
      dataSet?.DataLists?.AnonymousTravelerList?.AnonymousTraveler?.find(
        (item) => item.ObjectKey === ref
      )
    );
  }
  return dataSet?.DataLists?.AnonymousTravelerList?.AnonymousTraveler?.find(
    (item) => item.ObjectKey === refs
  )?.PTC;
};

export const getOfferItemID = (offer) => {
  return offer?.map((item) => {
    return {
      refs: item.RequestedDate.Associations[0].AssociatedTraveler
        .TravelerReferences,
      value: item.OfferItemID,
    };
  });
  // return offer.map((item) => {
  //   return item.RequestedDate.Associations?.map((item) => {
  //     return {
  //       refs: item.AssociatedTraveler.TravelerReferences,
  //       value: item.OfferItemID,
  //     };
  //   });
  // });
};

export function getPriceEach(price) {
  return price?.map((item) => {
    // return (
    //   item.RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.value *
    //   item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
    //     .length
    // );
    return {
      price:
        item.RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.value *
        item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
          .length,
      type: item.RequestedDate.Associations[0].AssociatedTraveler
        .TravelerReferences[0],
      currency:
        item.RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.Code,
      count:
        item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
          .length,
    };
  });
}

export const getTravelerType = (dataSet, ref) => {
  return dataSet?.AnonymousTraveler?.find((item) => item.ObjectKey === ref)?.PTC
    .value;
};

export const paymentReq = (data, basePrice) => {
  return {
    Payments: {
      Payment: [
        {
          Amount: {
            value: basePrice,
            Code: data.PricedFlightOffers.PricedFlightOffer[0].OfferPrice[0]
              .RequestedDate.PriceDetail.TotalAmount.SimpleCurrencyPrice.Code,
          },
          Method: {
            Cash: {
              CashInd: true,
            },
          },
        },
      ],
    },
  };
};

// NDC Price + Charge + NDC Charge
export const getTotalNDCPrice = (data, charge, NDCCharge) => {
  return data + charge + NDCCharge;
};

// Ndc Price + NDC Charge
export const getNDCPrice = (data, NDCCharge) => {
  return data + NDCCharge;
};

export const getNDCSC = (offer, NDCCharge, count = 1) => {
  const carrier = offer?.OfferID?.Owner;
  const { Associations } = offer?.PricedOffer;

  if (!Associations) return;

  let charge = NDCCharge?.find((item) => item.carrier === carrier);

  if (Associations?.length > 1) charge = charge?.round;
  else charge = charge?.oneWay;

  return charge * count;
};

export const getServiceCharge = (
  offer,
  datalist,
  company,
  corporate,
  cabinType,
  data,
  discount
) => {
  if (!company) return 0;

  const calculatePassengerPrices = (offer) => {
    const prices = {
      ADT: { price: 0, count: 0 },
      CHD: { price: 0, count: 0 },
    };

    offer.forEach((item) => {
      const travelers =
        item?.RequestedDate?.Associations[0]?.AssociatedTraveler
          ?.TravelerReferences;
      const price =
        item?.RequestedDate?.PriceDetail?.TotalAmount?.SimpleCurrencyPrice
          ?.value;
      const passengerType = getTravelerType2(datalist, travelers?.[0]);
      const count = travelers?.length ?? 1;

      if (passengerType === "ADT") {
        prices.ADT = { price: price * count, count };
      }
      if (passengerType === "CHD") {
        prices.CHD = { price: price * count, count };
      }
    });

    return prices;
  };

  const passengerPrices = calculatePassengerPrices(offer);
  const totalPassengers = passengerPrices.ADT.count + passengerPrices.CHD.count;

  let adultTotal = passengerPrices.ADT.price;
  let childTotal = passengerPrices.CHD.price;

  if (data?.passengerTypeState === "Non-Document-Holder") {
    adultTotal = +(adultTotal * corporate?.exchangeRate).toFixed(2);
    childTotal =
      childTotal > 0 ? +(childTotal * corporate?.exchangeRate).toFixed(2) : 0;
  }

  adultTotal /= passengerPrices.ADT.count || 1;
  childTotal /= passengerPrices.CHD.count || 1;

  let basePrice = 0;
  if (discount?.companyIds?.includes(company?._id)) {
    basePrice =
      discount?.serviceCharge *
      (passengerPrices.ADT.count + passengerPrices.CHD.count);
  } else {
    if (+data?.labors > 0 && company?.isLabor) {
      basePrice = company.serviceChargeLabor * +data.labors;
    } else if (data?.tripType === TRIP_TYPE_ONE_WAY && company?.isOneWay) {
      basePrice = company.serviceChargeOneWay * totalPassengers;
    } else {
      const adultCharge =
        getServiceChargeInternational(
          adultTotal,
          company?.serviceChargeInternational
        ) * (passengerPrices.ADT.count || +data?.labors);

      const childCharge =
        childTotal > 0
          ? getServiceChargeInternational(
              childTotal,
              company?.serviceChargeInternational
            ) * passengerPrices.CHD.count
          : 0;

      basePrice = adultCharge + childCharge;
    }
  }

  const isEconomyOrAny = cabinType === "Y" || cabinType === "Any";
  const isUSD = data?.currencyStates === "USD";

  if (isEconomyOrAny) {
    return isUSD ? basePrice : basePrice * corporate?.exchangeRate;
  }

  const businessCharge =
    company.serviceChargeInternationalBusiness * totalPassengers;
  return isUSD ? businessCharge : businessCharge * corporate?.exchangeRate;
};

export const getTravelerType2 = (data, ref) => {
  return data?.find((item) => item.ObjectKey === ref)?.PTC.value;
};

export const getNDCPassengerPrice = (item, adults, children, fees, type) => {
  const passengerType = ["ADT", "CHD"];
  const passengersLength = Number(adults) + Number(children);
  const singleNDC = fees?.NDC_SC / passengersLength;

  if (!passengerType.includes(type)) {
    return item.price;
  }

  switch (type) {
    case "ADT":
      return item.price + singleNDC * adults;
    case "CHD":
      return item.price + singleNDC * children;
    default:
      return item.price;
  }
};
