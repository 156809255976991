import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import convertNumbThousand from "../../utils/convertNumbThousand";
import API_URL from "../../redux2";
import Locals from "../../utils/localFlights";
import {
  INTERNATIONAL_SYSTEM_FEE,
  LOCAL_SYSTEM_FEE,
} from "../../common/constants";

const VoidPassengerModal = ({
  show,
  onHide,
  booking,
  setLoading,
  setError,
}) => {
  const passengers = booking?.passengers
    ?.map((passenger, index) => ({
      ...passenger,
      ticketNumber: booking?.flight?.ticketNumber?.[index],
    }))
    .filter((passenger) => passenger.status !== "voided");

  const [base, setBase] = useState(0);
  const [charge, setCharge] = useState(passengers?.length);
  const [voidPassenger, setVoidPassenger] = useState([]);

  const navigate = useNavigate();

  let service = booking?.serviceCharge / passengers?.length;

  const handleVoidPassenger = async (e) => {
    e.preventDefault();

    if (voidPassenger.length === 0) {
      toast.error("Please select at least one passenger to void");
      return;
    }

    let isLocal =
      Locals.includes(booking?.flight?.destination) &&
      Locals.includes(booking?.flight?.origin);

    const data = {
      ProviderLocatorCode: booking?.flight?.providerPnr,
      documentNumbers: [],
      bookingId: booking?._id,
      company: booking?.company?._id,
      type: passengers?.length === voidPassenger?.length ? "full" : "partial",
      totalPrice: booking?.flight?.price + service * charge - base,
      toWallet:
        booking?.totalPrice -
        (booking?.flight?.price + service * charge - base),
      basePrice: booking?.flight?.price - base,
      serviceCharge: service * charge,
      passengerIds: voidPassenger.map((passenger) => passenger._id),
      systemFee: +(
        isLocal
          ? LOCAL_SYSTEM_FEE * (passengers.length - voidPassenger?.length)
          : booking?.company
          ? service * charge * 0.2
          : INTERNATIONAL_SYSTEM_FEE *
            (passengers.length - voidPassenger?.length)
      ).toFixed(2),
    };

    for (let i = 0; i < voidPassenger?.length; i++) {
      data.documentNumbers.push({
        DocumentNumber: voidPassenger?.[i]?.ticketNumber,
        DocumentType: "E-Ticket",
      });
    }

    onHide();
    setLoading(true);
    try {
      const res = await axios.post(API_URL + "flight/void-ticket", data);

      if (
        res.data?.AirVoidDocumentRsp?.VoidResultInfo?.[0]?.FailureRemark
          ?._text ||
        res.data?.AirVoidDocumentRsp?.VoidResultInfo?.FailureRemark?._text
      ) {
        setError(
          res.data?.AirVoidDocumentRsp?.VoidResultInfo?.FailureRemark?._text
        );
        return;
      }
      toast.success("Booking voided successfully");
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        navigate(0);
      }, 1000);
      setLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-right"
    >
      <Modal.Body>
        <div className="max-w-lg w-full mx-auto">
          <h5 className="text-2xl font-semibold mb-6 text-center text-gray-800">
            Select Booking to Void
          </h5>
          <form action="">
            {/* Select All Button */}
            <div className="flex items-center justify-between mb-4 rounded-md">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="select-all"
                  className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                  checked={charge === 0}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    const newBase = isChecked ? booking?.flight?.price : 0;
                    const newCharge = isChecked ? 0 : passengers?.length;

                    setBase(newBase);
                    setCharge(newCharge);

                    passengers?.forEach((passenger, index) => {
                      const passengerCheckboxId = `void-passenger-${index}`;
                      const passengerCheckbox =
                        document.getElementById(passengerCheckboxId);

                      if (passengerCheckbox) {
                        passengerCheckbox.checked = isChecked;
                      } else {
                        console.warn(
                          `Element with id "${passengerCheckboxId}" not found.`
                        );
                      }
                    });

                    setVoidPassenger(
                      isChecked
                        ? passengers?.map((passenger, index) => ({
                            ...passenger,
                            ticketNumber:
                              booking?.flight?.ticketNumber?.[index],
                          }))
                        : []
                    );
                  }}
                />
                <label
                  htmlFor="select-all"
                  className="ml-3 block font-medium text-gray-700 cursor-pointer my-2 text-base"
                >
                  Select All
                </label>
              </div>
            </div>
            {passengers?.map((passenger, index) => (
              <div
                className="flex items-center justify-between mb-4 p-4 rounded-md bg-gray-100 hover:bg-gray-200 transition duration-150 ease-in-out"
                key={index}
              >
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="void-passenger"
                    id={`void-passenger-${index}`}
                    className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                    value={passenger.id}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setBase(base + passenger?.price);
                        setCharge(charge - 1);
                        setVoidPassenger([
                          ...voidPassenger,
                          {
                            ...passenger,
                          },
                        ]);
                      } else {
                        setBase(base - passenger?.price);
                        setCharge(charge + 1);
                        setVoidPassenger(
                          voidPassenger.filter(
                            (data) => data._id !== passenger._id
                          )
                        );
                      }
                    }}
                  />
                  <label
                    htmlFor={`void-passenger-${index}`}
                    className="ml-3 block font-medium text-gray-700 cursor-pointer text-base mt-2"
                  >
                    {passenger.firstName} {passenger.lastName}
                  </label>
                </div>
                <div className="text-gray-500">{passenger.ticketNumber}</div>
              </div>
            ))}
          </form>
          {/* show total price */}
          <div className="flex items-center justify-between mt-6 text-lg flex-wrap ">
            <div className="flex flex-row gap-2 mr-10">
              <div className="text-gray-600 ">Total Price:</div>
              <div className="text-gray-800 font-semibold">
                {convertNumbThousand(
                  booking?.flight?.price + service * charge - base
                )}
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="text-gray-600">System Fair :</div>
              <div className="text-gray-800 font-semibold">
                {convertNumbThousand(booking?.flight?.price - base)}
              </div>
            </div>

            <div className="flex flex-row gap-2">
              <div className="text-gray-600">Fetan S.C :</div>
              <div className="text-gray-800 font-semibold">
                {convertNumbThousand(service * charge)}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end mt-6 gap-3">
          <button
            type="button"
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out mr-2"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-rose-600 text-white rounded-md hover:bg-rose-700 transition duration-150 ease-in-out"
            onClick={handleVoidPassenger}
          >
            Void Booking
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default VoidPassengerModal;
