import React from "react";

const Footer = () => {
  var d = new Date();
  return (
    <div className="text-black z-0">
      <div className="text-center py-3 px-4">
        <p>
          Copyright © Designed &amp; Developed by{" "}
          <a
            href="http://bookingtechsolutions.com/"
            target="_blank"
            rel="noreferrer"
            className="text-main"
            style={{ color: "#FBA82C" }}
          >
            Booking Technologies
          </a>{" "}
          {d.getFullYear()}
        </p>
      </div>
    </div>
  );
};

export default Footer;
