import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import discountApi from "./discountApi";

// Get user from localStorage
const token = JSON.parse(localStorage.getItem("token"));

const initialState = {
  isError: false,
  isLoading: false,
  errorMessage: "",
  discount: null,
  token: token ? token : null,
};

export const createDiscount = createAsyncThunk(
  "discount/createDiscount",
  async (discount, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      const res = await discountApi.createDiscount(token, discount);
      if (res.success) toast.success("Discount created successfully");

      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getDiscountByDestination = createAsyncThunk(
  "discount/getDiscountByDestination",
  async (destination, thunkApi) => {
    try {
      const token = thunkApi.getState().auth.user.token;
      return await discountApi.getDiscountByDestination(token, destination);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const discountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.errorMessage = "";
      state.discount = null;
    },
  },
  extraReducers: {
    [createDiscount.pending]: (state) => {
      state.isLoading = true;
    },
    [createDiscount.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.discount = action.payload;
    },
    [createDiscount.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.discount = null;
    },
    [getDiscountByDestination.pending]: (state) => {
      state.isLoading = true;
    },
    [getDiscountByDestination.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.discount = action.payload.data;
    },
    [getDiscountByDestination.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
      state.discount = null;
    },
  },
});

export const { reset } = discountSlice.actions;
export default discountSlice.reducer;
