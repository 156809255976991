import axios from "axios";
import API_URL from "../../../redux2";

export const airBooking = async (Booking, requestData, setLoading) => {
  let header = {
    service: "OrderCreate",
    ThirdpartyId: Booking.Query?.OrderItems?.ShoppingResponse?.Owner,
  };

  const data = new FormData();
  data.append("bookingData", JSON.stringify({ Booking, requestData, header }));

  if (requestData?.files && requestData.files.length > 0) {
    for (let i = 0; i < requestData.files.length; i++) {
      data.append("files", requestData.files[i]);
    }
  }

  setLoading(true);
  // try {
  const res = await axios.post(API_URL + "/ndc/air-booking", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data;
  // } catch (error) {
  //   console.log(error);
  // } finally {
  //   setLoading(false);
  // }
};
