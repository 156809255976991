import moment from "moment";
import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import StatCard from "../StatCard";
import AddPCC from "./AddPCC";
import excelExport from "../../../utils/excelExport";
import Loading from "../../../components/Loading";
import CreditPayment from "./CreditPayment";
import { getCompany } from "../../../redux2/user/userSlice";
import { USER_ROLE_AGENT } from "../../../common/constants";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import DepositToWallet from "../DepositToWallet";
import bankListData from "./BankList";
import { getBilling } from "../../../redux2/billing/billingSlice";
import AgentBillingList from "./AgentBillingList";
import { formatNumber } from "../../../utils/convertToNumber";
import KachaLoanList from "./KachaLoanList";

const AgentBillingStat = ({ query }) => {
  const { billing, isLoading } = useSelector((state) => state.billing);
  const { company, isLoading: loading } = useSelector((state) => state.user);
  const { user, isLoading: loadingUser } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  query.limit = 10;

  useEffect(() => {
    dispatch(getCompany(user?._id));
  }, [dispatch, user]);

  useEffect(() => {
    let data = {
      query,
      id: company?._id,
    };
    dispatch(getBilling(data));
  }, [dispatch, company, query]);

  let FETAN_NAME = "Fetan Ticket";

  const bankAmount = billing?.bankBookings?.reduce(
    (acc, { _id, totalAmount }) => {
      acc[_id] = totalAmount;
      return acc;
    },
    {}
  );

  const totalAgentFee = billing?.billing?.reduce((total, item) => {
    if (item.commission && item.status !== "voided")
      return total + item.commission;
    return total;
  }, 0);

  const totalSales = billing?.billing?.reduce((total, item) => {
    if (item.amount && item.status === "confirmed")
      return total + item.amount + item.serviceCharge || 0;
    return total;
  }, 0);

  const statData = [
    // {
    //   title: "Total Sales Summary",
    //   data: convertNumbThousand(totalSales || 0) + " birr",
    //   icon: (
    //     <svg
    //       id="icon-revenue"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="30"
    //       height="30"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       stroke="currentColor"
    //       strokeWidth="2"
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //       className="feather feather-dollar-sign"
    //     >
    //       <line x1="12" y1="1" x2="12" y2="23"></line>
    //       <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    //     </svg>
    //   ),
    //   color: "success",
    // },
    {
      title: "Wallet Balance",
      data: formatNumber(billing?.company?.wallet?.toFixed(2) || 0) + " Birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-wallet2"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "primary",
    },
    {
      title: "Available Credit",
      data:
        formatNumber((billing?.company?.creditLimit || 0).toFixed(2)) + " Birr",
      // data: "200,000 birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
    {
      title: "Available USD Credit",
      data:
        formatNumber((billing?.company?.USDCreditLimit || 0).toFixed(2)) +
        " USD",
      // data: "200,000 birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
    {
      title: "Total Agent Fee",
      data: formatNumber((totalAgentFee || 0).toFixed(2)) + " Birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "success",
    },

    // {
    //   title: "Credit Limit",
    //   data: formatNumber(billing?.company?.totalCreditLimit) + " Birr",
    //   // data: "500,000 birr",
    //   icon: (
    //     <svg
    //       id="icon-revenue"
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="30"
    //       height="30"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       stroke="currentColor"
    //       strokeWidth="2"
    //       strokeLinecap="round"
    //       strokeLinejoin="round"
    //       className="feather feather-dollar-sign"
    //     >
    //       <line x1="12" y1="1" x2="12" y2="23"></line>
    //       <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
    //     </svg>
    //   ),
    //   color: "danger",
    // },
    // {
    //   title: "Unpaid Amount",
    //   data:
    //     formatNumber(
    //       `${billing?.company?.totalCreditLimit}` -
    //         `${billing?.company?.creditLimit}`
    //     ) + " birr",
    //   // data: "350,000 birr",
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       data-name="Layer 1"
    //       viewBox="0 0 24 24"
    //       id="IconChangeColor"
    //       height="30"
    //       width="30"
    //     >
    //       <path
    //         d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm5.86-1.55h0L4.71,2.29A1,1,0,0,0,3.29,3.71L4.59,5H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H18.59l2.7,2.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm-.74,2.09,1.34,1.34A1,1,0,0,1,12,13a1,1,0,0,1-1-1A1,1,0,0,1,11.12,11.54ZM4,17a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H6.59l3.1,3.1A3,3,0,0,0,9,12a3,3,0,0,0,3,3,3,3,0,0,0,1.9-.69L16.59,17ZM20,5H12.66a1,1,0,0,0,0,2H20a1,1,0,0,1,1,1v7.34a1,1,0,1,0,2,0V8A3,3,0,0,0,20,5Zm-1,7a1,1,0,1,0-1,1A1,1,0,0,0,19,12Z"
    //         id="mainIconPathAttribute"
    //       ></path>
    //     </svg>
    //   ),
    //   color: "success",
    // },
  ];

  const FetanStatData = [
    {
      title: "Total Sales",
      data:
        convertNumbThousand(billing?.totalBooking?.[0]?.totalAmount || 0) +
        " birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "success",
    },

    {
      title: "Base Price",
      data: formatNumber(billing?.totalBooking?.[0]?.price || 0) + " birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Service Charge",
      data:
        convertNumbThousand(billing?.totalBooking?.[0]?.serviceCharge || 0) +
        " birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="IconChangeColor"
          height="30"
          width="30"
        >
          <path
            d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm5.86-1.55h0L4.71,2.29A1,1,0,0,0,3.29,3.71L4.59,5H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H18.59l2.7,2.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm-.74,2.09,1.34,1.34A1,1,0,0,1,12,13a1,1,0,0,1-1-1A1,1,0,0,1,11.12,11.54ZM4,17a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H6.59l3.1,3.1A3,3,0,0,0,9,12a3,3,0,0,0,3,3,3,3,0,0,0,1.9-.69L16.59,17ZM20,5H12.66a1,1,0,0,0,0,2H20a1,1,0,0,1,1,1v7.34a1,1,0,1,0,2,0V8A3,3,0,0,0,20,5Zm-1,7a1,1,0,1,0-1,1A1,1,0,0,0,19,12Z"
            id="mainIconPathAttribute"
          ></path>
        </svg>
      ),
      color: "success",
    },
    {
      title: "System Fee",
      data:
        convertNumbThousand(billing?.totalBooking?.[0]?.systemFee || 0) +
        " birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "success",
    },
    {
      title: "Net Revenue",
      data:
        convertNumbThousand(billing?.totalBooking?.[0]?.netRevenue || 0) +
        " birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },

    {
      title: "Total Tax and Penalty",
      // data: convertNumbThousand(calculateTaxPenalty) + " birr",
      data: "0 birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },

    {
      title: "Total Sold Tickets",
      data: "0 birr",
      // data: formatNumber(countAllAgentTickets() + countAllSuperTickets()) || 0,
      icon: (
        // ticket svg
        <svg
          id="icon-ticket"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          // strokeLinecap="round"
          // strokeLinejoin="round"
          // className="feather feather-dollar-sign"
        >
          <path
            d="M19,7V19a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V7A2,2,0,0,1,7,5h5l2-2h4a2,2,0,0,1,2,2Z"
            id="mainIconPathAttribute"
          ></path>
          <polyline
            points="16 2 16 8 22 8"
            id="mainIconPathAttribute"
          ></polyline>
          <line
            x1="12"
            y1="18"
            x2="12"
            y2="12"
            id="mainIconPathAttribute"
          ></line>
          <line
            x1="9"
            y1="15"
            x2="15"
            y2="15"
            id="mainIconPathAttribute"
          ></line>
        </svg>
      ),
      color: "warning",
    },
  ];

  const calculatePercentage = (price, commission) => {
    if (price === 0 || price === undefined) {
      return 0;
    }
    return ((commission / price) * 100).toFixed(2) + " %";
  };

  function handleExport() {
    let name = "Agent Billing" + moment().format("DD-MM-YYYY");
    const data = billing?.billing?.map((billing) => {
      return {
        PaymentMethod: billing?.bank,
        Status: billing?.status,
        TotalAmount:
          billing.deposit ||
          billing.amount +
            (billing.commission || 0) +
            (billing.serviceCharge || 0),
        BasePrice: billing?.amount || 0,
        AgentFee: billing?.commission,
        AgentFeePercentage: calculatePercentage(
          billing?.basePrice,
          billing?.commission
        ),
        ServiceCharge: billing?.serviceCharge,
        TransactionId: billing?.transaction,
        CreatedAt: billing?.createdAt,
      };
    });
    excelExport(data, name);
  }

  if (isLoading || loading || loadingUser) {
    return <Loading />;
  }

  return (
    <>
      <div>
        <div className="row">
          {user?.role === USER_ROLE_AGENT && company?.name === FETAN_NAME ? (
            <>
              {FetanStatData.map(({ icon, color, title, data }, index) => (
                <StatCard
                  key={index}
                  icon={icon}
                  color={color}
                  title={title}
                  data={data}
                />
              ))}
              <h1>
                <span className="text-2xl font-semibold px-2 py-4">
                  Bank Transaction Stat
                </span>
              </h1>
              {/* {bankStatData.map(({ icon, color, title, data }, index) => (
          <StatCard
            key={index}
            icon={icon}
            color={color}
            title={title}
            data={data}
          />
        ))} */}
              {bankListData(bankAmount, formatNumber).map(
                ({ icon, color, title, data }, index) => (
                  <StatCard
                    key={index}
                    icon={icon}
                    color={color}
                    title={title}
                    data={data}
                  />
                )
              )}
            </>
          ) : (
            statData.map(({ icon, color, title, data }, index) => (
              <StatCard
                key={index}
                icon={icon}
                color={color}
                title={title}
                data={data}
              />
            ))
          )}
        </div>
      </div>

      <div className="flex justify-end gap-4">
        <div className="">
          {/* <div
            className="btn outline  text-black hover:bg-gray-100 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:shadow-xl
          focus:outline-none mb-xxl-0 mb-4 mx-3"
          >
            <AddPCC />
          </div>

          <div className="btn bg-secondary  text-white border-main-100 mb-xxl-0 mb-4 mx-3">
            <DepositToWallet />
          </div> */}

          <Dropdown className="dropdown text-sans-serif">
            <Dropdown.Toggle
              // id="dropdown"
              variant=""
              className="p-2 text-white border-main-100 mx-3 px-4 border-1"
              type="button"
              id="order-dropdown-0"
              data-toggle="dropdown"
              data-boundary="viewport"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="flex">
                <h1 className="mx-2">Action</h1>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <circle fill="#000000" cx={5} cy={12} r={2} />
                      <circle fill="#000000" cx={12} cy={12} r={2} />
                      <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                  </svg>
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropdown-menu dropdown-menu-right border py-0"
              aria-labelledby="order-dropdown-0"
              style={{ minWidth: "20rem" }}
            >
              <div className="mt-2 px-4 mx-2 text-black font-medium text-lg">
                <div className="py-2 hover:text-main">
                  <AddPCC />
                </div>

                <div className="py-2 hover:text-main">
                  <DepositToWallet />
                </div>

                <div className="py-2 hover:text-main">
                  <CreditPayment company={company} />
                </div>
                <div className="py-2 hover:text-main">
                  <KachaLoanList company={company} />
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <button
          className="btn bg-main hover:bg-main-800 text-white border-main-100 mb-xxl-0 mb-4"
          onClick={handleExport}
        >
          <i className="far fa-file-word me-2"></i>Generate Report
        </button>
      </div>

      <AgentBillingList billing={billing} />
    </>
  );
};

export default AgentBillingStat;
