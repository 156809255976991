import clsx from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import UserMenu from "./UserMenu";
import { getUnseenRequests } from "../../redux2/request/requestSlice";
import {
  USER_ROLE_AGENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_ENTERPRISE,
  USER_ROLE_FINANCE,
  USER_ROLE_MEMBER,
  USER_ROLE_SUPERAGENT,
} from "../../common/constants";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { length: requestCount } = useSelector((state) => state.request);

  // useEffect(() => {
  //   if (user?.role === USER_ROLE_SUPERAGENT) {
  //     dispatch(getUnseenRequests());
  //   }
  // }, [user?.role, dispatch]);

  const agentHeaderLinks = [
    { title: "Find Flights", route: "/" },
    { title: "Booking", route: "/booking" },
    { title: "Billing", route: "/billing-agent" },
    { title: "Members", route: "/members" },
    // { title: "Contact", route: "/contact" },
  ];

  const enterpriseHeaderLinks = [
    { title: "Find Flights", route: "/" },
    { title: "Booking", route: "/booking" },
    { title: "Billing", route: "/billing-enterprise" },
    // { title: "Members", route: "/members" },
    // { title: "Contact", route: "/contact" },
  ];

  const superAgentHeaderLinks = [
    // { title: "Find Flights", route: "/" },
    { title: "Booking", route: "/booking" },
    { title: "Billing", route: "/billing" },
    { title: "Agents", route: "/agents" },
    { title: "Credit", route: "/credit" },
    { title: "Dashboard", route: "/dashboard" },
    { title: "Requests", route: "/requests", badge: requestCount },
    // { title: "Contact", route: "/contact" },
  ];

  const employeeHeaderLinks = [
    { title: "Find Flights", route: "/" },
    { title: "Booking", route: "/booking" },
    { title: "Billing", route: "/billing" },
    { title: "Credit", route: "/credit" },
    { title: "Requests", route: "/requests", badge: requestCount },
    // { title: "Contact", route: "/contact" },
  ];

  const memberHeaderLinks = [
    { title: "Find Flights", route: "/" },
    { title: "Booking", route: "/booking" },
    // { title: "Contact", route: "/contact" },
  ];

  const financeHeaderLinks = [
    { title: "Booking", route: "/booking" },
    { title: "Billing", route: "/billing" },
    { title: "Agents", route: "/agents" },
    // { title: "Contact", route: "/contact" },
  ];

  return (
    <div className="py-2">
      <div className="header-content px-4 ">
        <nav className="navbar navbar-expand-lg justify-content-end">
          <button
            className="navbar-toggler justify-end items-end"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon flex flex-wrap"></span>
          </button>
          <div
            className="justify-content-between collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <div></div>
            <div className="header-left">
              <div className=" navbar-nav">
                {user?.role === USER_ROLE_AGENT ? (
                  <>
                    {agentHeaderLinks.map(({ title, route }, index) => (
                      <div
                        key={index}
                        className={clsx(
                          "fs-4 cursor-pointer nav-item px-3 py-1 mt-2 relative",
                          {
                            "text-main": location?.pathname === route,
                          }
                        )}
                        onClick={() => navigate(route)}
                        style={{ paddingLeft: 50 }}
                      >
                        {title}
                      </div>
                    ))}
                  </>
                ) : user?.role === USER_ROLE_ENTERPRISE ? (
                  <>
                    {enterpriseHeaderLinks.map(({ title, route }, index) => (
                      <div
                        key={index}
                        className={clsx(
                          "fs-4 cursor-pointer nav-item px-3 py-1 mt-2 relative",
                          {
                            "text-main": location?.pathname === route,
                          }
                        )}
                        onClick={() => navigate(route)}
                        style={{ paddingLeft: 50 }}
                      >
                        {title}
                      </div>
                    ))}
                  </>
                ) : user?.role === USER_ROLE_EMPLOYEE ? (
                  <>
                    {employeeHeaderLinks.map(({ title, route }, index) => (
                      <div
                        key={index}
                        className={clsx(
                          "fs-4 cursor-pointer nav-item px-3 py-1 mt-2 relative",
                          {
                            "text-main": location?.pathname === route,
                          }
                        )}
                        onClick={() => navigate(route)}
                        style={{ paddingLeft: 50 }}
                      >
                        {title}
                      </div>
                    ))}
                  </>
                ) : user?.role === USER_ROLE_FINANCE ? (
                  <>
                    {financeHeaderLinks.map(({ title, route }, index) => (
                      <div
                        key={index}
                        className={clsx(
                          "fs-4 cursor-pointer nav-item px-3 py-1 mt-2 relative",
                          {
                            "text-main": location?.pathname === route,
                          }
                        )}
                        onClick={() => navigate(route)}
                        style={{ paddingLeft: 50 }}
                      >
                        {title}
                      </div>
                    ))}
                  </>
                ) : user?.role === USER_ROLE_MEMBER ? (
                  <>
                    {memberHeaderLinks.map(({ title, route }, index) => (
                      <div
                        key={index}
                        className={clsx(
                          "fs-4 cursor-pointer nav-item px-3 py-1 mt-2 relative",
                          {
                            "text-main": location?.pathname === route,
                          }
                        )}
                        onClick={() => navigate(route)}
                        style={{ paddingLeft: 50 }}
                      >
                        {title}
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {superAgentHeaderLinks.map(
                      ({ title, route, badge }, index) => (
                        <div
                          key={index}
                          className={clsx(
                            "fs-4 cursor-pointer nav-item px-3 py-1 mt-2 relative",
                            {
                              "text-main": location?.pathname === route,
                            }
                          )}
                          onClick={() => navigate(route)}
                          style={{ paddingLeft: 50 }}
                        >
                          {badge > 0 && (
                            <span className="w-5 h-5 absolute top-0 -right-1  rounded-full bg-red-500 text-white text-xs flex items-center justify-center">
                              {badge}
                            </span>
                          )}
                          {title}
                        </div>
                      )
                    )}
                  </>
                )}

                <button className="px-2 pt-2 nav-item font-normal">
                  <a
                    href="https://www.iatatravelcentre.com"
                    target="_blank"
                    rel="noreferrer"
                    className="fs-4 cursor-pointer"
                  >
                    Timatic
                  </a>
                </button>
              </div>
            </div>
            <ul className="navbar-nav header-right main-notification nav-item ">
              <UserMenu />
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
