import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import axios from "axios";

import API_URL from "../../../redux2";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { closeModal, openModal } from "../../../redux2/modal/modalSlice";
import KachaCreditOTP from "../../../components/FlightBookingSteps/KachaCreditOTP";

const KachaLoanList = ({ company }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loanList, setLoanList] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState([]);
  const [partialAmount, setPartialAmount] = useState(null);

  const dispatch = useDispatch();

  const handleClick = async () => {
    try {
      setIsLoading(true);
      // let phone = "251923130324";
      let phone = company?.officeNumber;
      const res = await axios.get(API_URL + `kacha?phone=${phone}`);

      setLoanList(res.data);

      setIsModalOpen(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (partialAmount) {
      if (partialAmount < 200) {
        toast.error("Minimum amount to pay is 200 ETB");
        return;
      }
      if (partialAmount > getUnpaid()) {
        toast.error("Amount to pay is greater than the unpaid amount");
        return;
      }
    }

    let amount =
      partialAmount && partialAmount > 100 ? partialAmount : getUnpaid();
    const req = {
      loans: selectedLoan.map((loan) => loan.code),
      // phone: "251923130324",
      phone: company?.officeNumber,
      partial: false,
      // amount: String(amount),
    };

    try {
      setIsLoading(true);
      const res = await axios.post(API_URL + "kacha/repayment", req);

      console.log(JSON.stringify(res.data));

      const data = {
        kacha: res.data,
        repayment: true,
        code: selectedLoan[0]?.code,
      };
      setIsModalOpen(false);

      let modalType = "Kacha";
      dispatch(openModal({ modalType, data }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setSelectedLoan([]);
    }
  };

  function getUnpaid() {
    let total = 0;
    selectedLoan.forEach((loan) => {
      total += loan?.outstanding;
    });
    return total;
  }

  function getPrinciple() {
    let total = 0;
    selectedLoan.forEach((loan) => {
      total += loan?.un_paid_principal;
    });
    return total;
  }

  function getInterest() {
    let total = 0;
    selectedLoan.forEach((loan) => {
      total += loan?.un_paid_total_fee;
    });
    return total;
  }

  function remainingDays(loan) {
    const expectedDate = moment(new Date(loan.expected_at), "DD-MM-YYYY");
    const currentDate = moment();

    // console.log("Expected Date:", expectedDate.format("DD-MM-YYYY"));
    // console.log("Current Date:", currentDate.format("MM-DD-YYYY"));

    const daysDifference = expectedDate.diff(new Date(currentDate), "days");

    return daysDifference;
  }

  async function handleKachaSubmit(kacha, otp) {
    try {
      setIsLoading(true);
      const request = {
        reference: kacha?.id,
        // code: selectedLoan[0]?.code,
        otp: otp,
      };

      const res = await axios.post(
        `${API_URL}kacha/authorize-repayment`,
        request
      );

      if (res) {
        toast.success("Loan Repayment Successful");
      }

      dispatch(closeModal("Kacha"));
    } catch (error) {
      console.log(error.response.data);

      let message =
        error.response.data.error.message || error.response.data.error;
      toast.error(message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className="cursor-pointer" onClick={handleClick}>
        {isLoading ? "Loading ..." : " + Kacha Loan"}
      </div>

      <Modal
        show={isModalOpen}
        className="modal fade"
        onHide={() => {
          setIsModalOpen(false);
          setSelectedLoan([]);
        }}
        size="lg"
        centered
      >
        <Modal.Body>
          <div className="w-full ">
            <h5 className="text-2xl font-semibold mb-6 text-center text-gray-800">
              Select Loan to Settle
            </h5>
            <h6 className="text-xl py-2 font-medium">
              <span className="text-gray-600">Beneficiary Name: </span>
              <span className="text-gray-800">
                {loanList?.results?.[0]?.borrower_name}
              </span>
            </h6>
            <form action="">
              <div className="flex items-center justify-between mb-4 rounded-md">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="select-all"
                    className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                    checked={selectedLoan.length === loanList?.count}
                    onChange={(e) => {
                      const isChecked = e.target.checked;

                      loanList?.results?.forEach((_, index) => {
                        const loanCheckboxId = `loan-ledger-${index}`;
                        const loanCheckbox =
                          document.getElementById(loanCheckboxId);

                        if (loanCheckbox) {
                          loanCheckbox.checked = isChecked;
                        } else {
                          console.warn(
                            `Element with id "${loanCheckboxId}" not found.`
                          );
                        }
                      });

                      setSelectedLoan(
                        isChecked
                          ? loanList?.results?.map((loan) => ({
                              ...loan,
                              code: loan?.code,
                            }))
                          : []
                      );
                    }}
                  />
                  <label
                    htmlFor="select-all"
                    className="ml-3 block font-medium text-gray-700 cursor-pointer my-2 text-base"
                  >
                    Select All
                  </label>
                </div>
                <div className="text-base font-medium">
                  <h1>Total Loan: {loanList?.count}</h1>
                </div>
              </div>
              <div className="">
                <div className="">
                  <div className="grid grid-cols-7 items-center justify-between mb-4 p-4 rounded-md bg-gray-600">
                    <div className="text-white col-span-2">
                      Beneficiary Name
                    </div>
                    <div className="text-white">Loan Code</div>
                    <div className="text-white">Status</div>
                    <div className="text-white">Principal</div>
                    <div className="text-white">Unpaid </div>
                    <div className="text-white">Expected At</div>
                    {/* <div className="text-white">Action</div> */}
                  </div>
                </div>
                {loanList?.results?.map((loan, index) => (
                  <div
                    className="grid grid-cols-7 items-center justify-between mb-4 p-3 rounded-md bg-gray-100 hover:bg-gray-200 transition duration-150 ease-in-out"
                    key={index}
                  >
                    <div className="flex items-center col-span-2">
                      <input
                        type="checkbox"
                        name="loan-ledger"
                        id={`loan-ledger-${index}`}
                        className="form-radio h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                        value={loan.code}
                        onChange={(e) => {
                          setSelectedLoan(
                            e.target.checked
                              ? [
                                  ...selectedLoan,
                                  {
                                    ...loan,
                                    code: loan?.code,
                                  },
                                ]
                              : selectedLoan.filter(
                                  (data) => data.code !== loan.code
                                )
                          );
                        }}
                        // onChange={() => {
                        //   setSelectedLoan([{ ...loan, code: loan?.code }]);
                        // }}
                      />
                      <label
                        htmlFor={`loan-ledger-${index}`}
                        className="ml-3 block font-medium text-gray-700 cursor-pointer text-base mt-2"
                      >
                        {loan.borrower_name}
                      </label>
                    </div>
                    <div className="text-black">{loan?.code}</div>
                    <div className="text-black">{loan?.status}</div>
                    <div className="text-black">ETB {loan?.principal}</div>
                    <div className="text-black">ETB {loan?.outstanding}</div>
                    <div className="text-black relative">
                      {moment(loan?.expected_at).format("DD-MM-YYYY")}
                      {/* <p>({moment(loan?.expected_at).fromNow(true)})</p> */}
                      <span className="bg-red-500 w-5 h-5 rounded-full flex items-center justify-center absolute bottom-4 right-5">
                        <i className=" text-white text-xs">
                          {/* {moment
                            .duration(moment(loan?.expected_at).diff(moment()))
                            .days()} */}
                          {remainingDays(loan)}
                        </i>
                      </span>
                    </div>
                    {/* <div
                      className={`w-10 h-10 bg-neutral-50 rounded-full flex items-center justify-center cursor-pointer ${
                        isOpen ? "transform -rotate-180" : ""
                      }`}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </div> */}
                  </div>
                ))}
              </div>
            </form>

            <div className="flex items-center justify-between mt-6 text-lg flex-wrap border-1 border-gray-600 rounded-lg p-2">
              <div className="flex flex-row gap-2 mr-10">
                <div className="text-gray-600 ">Total Unpaid Ledger:</div>
                <div className="text-gray-800 font-semibold">
                  ETB {convertNumbThousand(getUnpaid())}
                </div>
              </div>

              <div className="flex flex-row gap-2">
                <div className="text-gray-600">Principle :</div>
                <div className="text-gray-800 font-semibold">
                  ETB {convertNumbThousand(getPrinciple())}
                </div>
              </div>

              <div className="flex flex-row gap-2">
                <div className="text-gray-600">Total Fee :</div>
                <div className="text-gray-800 font-semibold">
                  ETB {convertNumbThousand(getInterest())}
                </div>
              </div>
            </div>
            {/* <div className="mt-3">
              <h1 className="text-base font-medium mb-2">
                Partial Loan Repayment Amount
              </h1>
              <div className="flex items-center  gap-2 mt-2">
                <div className="text-gray-600">Amount</div>
                <input
                  type="number"
                  className="border-1 border-black focus:outline-none focus:ring-black focus:ring-opacity-50 w-1/4 p-2 rounded-lg text-black"
                  placeholder="Enter Payment Amount"
                  required
                  onWheel={(e) => e.target.blur()}
                  value={partialAmount}
                  onChange={(e) => setPartialAmount(e.target.value)}
                  max={getUnpaid()}
                  min={200}
                />
                <div className="text-gray-600">ETB</div>
              </div>
              <span className="text-red-500 my-2">
                * To settle partial payment, enter the amount you want to pay{" "}
                <br /> * To settle full payment, leave the amount empty
              </span>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="flex justify-end mt-6 gap-3">
            <button
              type="button"
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition duration-150 ease-in-out mr-2"
              onClick={() => {
                setIsModalOpen(false);
                setSelectedLoan([]);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`px-4 py-2 bg-main  text-white rounded-md  transition duration-150 ease-in-out ${
                selectedLoan.length === 0
                  ? "cursor-not-allowed bg-grey-200"
                  : "hover:bg-main"
              }`}
              onClick={handleSubmit}
              disabled={selectedLoan.length === 0}
            >
              {isLoading
                ? "Loading..."
                : partialAmount
                ? "Settle Partial Payment"
                : "Settle Loan Payment"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <KachaCreditOTP handleSubmit={handleKachaSubmit} />
    </div>
  );
};

export default KachaLoanList;
