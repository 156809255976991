import { Pagination, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import EditBilling from "../Modals/EditBilling";
import { Dropdown } from "react-bootstrap";
import { formatNumber } from "../../utils/convertToNumber";

const Overall = ({ billings, currentPage, setCurrentPage }) => {
  // const billingPerPage = 10;

  const currentBillings = billings?.billings?.data;

  // const indexOfLastProduct = currentPage * billingPerPage;
  // const indexOfFirstProduct = indexOfLastProduct - billingPerPage;
  // const currentBillings = billings?.billings?.data?.slice(
  //   indexOfFirstProduct,
  //   indexOfLastProduct
  // );

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body " style={{ padding: "1.25rem" }}>
            <div className="table-responsive">
              <table className="table table-sm mb-0 table-responsive-lg default-table dataTable text-xs">
                <thead>
                  <tr className="text-black">
                    {/* <th></th> */}
                    <th className="align-middle">Status</th>
                    <th className="align-middle">Created At</th>
                    <th className="align-middle">Company Name</th>
                    <th className="align-middle">Transaction ID</th>
                    <th className="align-middle">Ticket Number</th>
                    <th className="align-middle">Payment Method</th>
                    <th className="align-middle">Deposit Amount</th>
                    <th className="align-middle pr-7">Total Price</th>
                    <th className="align-middle pr-7">System Fare</th>
                    <th className="align-middle ">Fetan S.C</th>
                    <th className="align-middle ">NDC S.C</th>
                    <th className="align-middle ">System Fee</th>
                    <th className="align-middle ">Net Revenue</th>
                    <th className="align-middle ">Agent Fee</th>
                    <th className="align-middle ">Passenger Name</th>
                    <th className="align-middle ">From Ticket Number</th>
                    <th className="align-middle ">Ticket Issue Date</th>
                    <th className="align-middle ">Tax</th>
                    <th className="align-middle ">Penalty</th>
                    <th className="align-middle ">Fare Difference</th>
                    <th className="align-middle ">Reason</th>
                    <th className="align-middle ">Withdrawn By</th>
                    <th className="align-middle ">Cheque Number</th>
                    <th className="align-middle ">Paid By</th>
                    <th className="align-middle ">Approved By</th>
                    <th className="align-middle">Balance</th>
                    <th className="align-middle">Action</th>
                    <th className="no-sort" />
                  </tr>
                </thead>
                <tbody id="orders">
                  {currentBillings?.length === 0 ? (
                    <tr>
                      <td colSpan={11}>
                        <Typography variant="h5">No Billing Found</Typography>
                      </td>
                    </tr>
                  ) : (
                    currentBillings?.map((item, index) => (
                      <tr className="btn-reveal-trigger" key={index}>
                        <td>
                          {item?.type === "refundDeposit" ? (
                            <span className="badge bg-red-600">
                              Refund Deposit
                            </span>
                          ) : item?.type === "voidDeposit" ? (
                            <span className="badge bg-blue-600">
                              Void Deposit
                            </span>
                          ) : item?.reason ? (
                            <span className="badge badge-info">
                              Credit Payment
                            </span>
                          ) : item?.deposit ? (
                            <span className="badge badge-dark">
                              Wallet Deposit
                            </span>
                          ) : item?.status === "refunded" ? (
                            <span className="badge badge-danger">Refunded</span>
                          ) : item?.status === "voided" ? (
                            <span className="badge badge-secondary">Void</span>
                          ) : item?.status === "void-late" ? (
                            <span className="badge badge-secondary">
                              Void-Late
                              <span className="ms-1 fa fa-check" />
                            </span>
                          ) : item?.status === "exchanged" ? (
                            <span className="badge bg-green-700">
                              Exchanged
                            </span>
                          ) : item?.type === "exchangeFee" ? (
                            <span className="badge bg-green-700">
                              Exchanged Fee
                            </span>
                          ) : (
                            <span className="badge badge-success">
                              Success
                              <span className="ms-1 fa fa-check" />
                            </span>
                          )}
                        </td>
                        <td className="py-2">
                          {moment(item.createdAt).format("MMMM Do YYYY")}
                        </td>
                        <td className="py-2">
                          {item.company?.name
                            ? item.company?.name
                            : "Deleted Company"}
                        </td>
                        <td className="py-2">{item?.transaction}</td>
                        <td>
                          {item?.ticketNumber?.map((ticket, index) => (
                            <div className="flex flex-col" key={index}>
                              {ticket}
                            </div>
                          ))}
                        </td>
                        <td className="py-2">{item.bank}</td>
                        <td className="py-2">
                          {item.deposit && item?.deposit + " birr"}
                        </td>
                        <td className="py-2">
                          {formatNumber(
                            item.amount +
                              (item?.serviceCharge || 0) +
                              (item?.NDCServiceCharge || 0) || 0
                          )}{" "}
                          birr
                        </td>
                        <td className="py-2">
                          {formatNumber(item.amount)} birr
                        </td>
                        <td className="py-2">
                          {formatNumber(item.serviceCharge || 0)} birr
                        </td>
                        <td className="py-2">
                          {formatNumber(item.NDCServiceCharge || 0)} birr
                        </td>
                        <td className="py-2">
                          {formatNumber(item.systemFee || 0)} birr
                        </td>
                        <td className="py-2">
                          {formatNumber(
                            item.serviceCharge +
                              (item.NDCServiceCharge || 0) -
                              item.systemFee || 0
                          )}{" "}
                          birr
                        </td>
                        <td className="py-2">
                          {formatNumber(item.commission || 0)} birr
                        </td>
                        <td className="py-2">
                          {item.booking?.passengers[0]
                            ? item.booking?.passengers[0]?.firstName +
                              " " +
                              item.booking?.passengers[0]?.lastName
                            : ""}
                        </td>
                        <td>
                          {item?.type === "exchangeFee"
                            ? item?.booking?.flight?.exTicketNumber?.map(
                                (ticket, index) => (
                                  <div className="flex flex-col" key={index}>
                                    {ticket}
                                  </div>
                                )
                              )
                            : item?.booking?.flight?.ticketNumber?.map(
                                (ticket, index) => (
                                  <div className="flex flex-col" key={index}>
                                    {ticket}
                                  </div>
                                )
                              )}
                        </td>
                        <td className="py-2">
                          {moment(item?.booking?.createdAt).format(
                            "MMMM Do YYYY"
                          )}
                        </td>
                        <td className="py-2">{item.tax || 0}</td>
                        <td className="py-2">{item.penalty || 0}</td>
                        <td className="py-2">{item.exchangeDifference || 0}</td>
                        <td className="py-2">{item.reason}</td>
                        <td className="py-2">{item.withdrawMethod}</td>
                        <td className="py-2">{item.chequeNumber}</td>
                        <td className="py-2">{item.paidBy}</td>
                        <td className="py-2">{item.approvedBy}</td>
                        <td className="py-2">
                          {item.balance <= 0
                            ? formatNumber(item.company?.wallet.toFixed(2)) +
                              " birr"
                            : formatNumber(item.balance.toFixed(2)) + " birr"}
                        </td>
                        <td className="py-2">
                          <Dropdown className="dropdown text-sans-serif">
                            <Dropdown.Toggle
                              // id="dropdown"
                              variant=""
                              className="text-primary hover:bg-main-200 i-false tp-btn-light sharp"
                              type="button"
                              id="order-dropdown-0"
                              data-toggle="dropdown"
                              data-boundary="viewport"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  width="18px"
                                  height="18px"
                                  viewBox="0 0 24 24"
                                  version="1.1"
                                >
                                  <g
                                    stroke="none"
                                    strokeWidth={1}
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <rect x={0} y={0} width={24} height={24} />
                                    <circle
                                      fill="#000000"
                                      cx={5}
                                      cy={12}
                                      r={2}
                                    />
                                    <circle
                                      fill="#000000"
                                      cx={12}
                                      cy={12}
                                      r={2}
                                    />
                                    <circle
                                      fill="#000000"
                                      cx={19}
                                      cy={12}
                                      r={2}
                                    />
                                  </g>
                                </svg>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              className="dropdown-menu dropdown-menu-right border py-0"
                              aria-labelledby="order-dropdown-0"
                            >
                              <button className="dropdown-item">
                                <EditBilling billing={item} />
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="flex items-center justify-center my-5">
                <Pagination
                  count={Math.ceil(billings?.billings?.pagination?.total)}
                  page={currentPage}
                  onChange={handlePageChange}
                  siblingCount={4}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overall;
