import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";

import { getBookingById } from "../../../redux2/booking/bookingSlice";
import { getMyCompany } from "../../../redux2/user/userSlice";
import {
  DIRECT_PAYMENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_ENTERPRISE,
  USER_ROLE_SUPERAGENT,
  WALLET_MINIMUM_AMOUNT,
} from "../../../common/constants";
import { getMyEnterprise } from "../../../redux2/enterprise/enterpriseSlice";
import { currencyType, getCarrierLogo } from "../../../utils/flightInfo";
import API_URL from "../../../redux2";
import {
  getLoanProduct,
  initiateLoanRequest,
  requestLoan,
} from "../../../services/payment";
import { openModal } from "../../../redux2/modal/modalSlice";
import Loading from "../../Loading";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import NcImage from "../../NcImage/NcImage";
import NcModal from "../../NcModal/NcModal";
import PaymentMethodsList from "../../PaymentMethods/PaymentMethodsList";
import { paymentMethods } from "../../../utils/paymentMethods";
import ButtonPrimary from "../../Button/ButtonPrimary";
import KachaCreditOTP from "../../FlightBookingSteps/KachaCreditOTP";
import CorporateList from "../../FlightBookingSteps/CorporateList";
import {
  getNDCPassengerPrice,
  getPriceEach,
  getTravelerType,
  paymentReq,
} from "../helpers";
import { airBooking } from "../Services/AirBooking";

const NDCInstantPayment = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("wallet");
  const [selectedCorporate, setSelectedCorporate] = useState(null);
  const [showCorporateList, setShowCorporateList] = useState(false);
  const [payment, setPayment] = useState(null);
  const [kachaInfo, setKachaInfo] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const adults = query.get("adults");
  const children = query.get("children");
  const infants = query.get("infants");
  const passengerTypeState = query.get("passengerTypeState");

  const { response, flight, basePrice, passengersData, files } = location.state;

  const fees = passengersData?.OtherInfo;

  const { booking, isLoading } = useSelector((state) => state.booking);
  const { company } = useSelector((state) => state.user);
  const { enterprise } = useSelector((state) => state.enterprise);
  const { user } = useSelector((state) => state.auth);

  const { id } = useParams();

  const currency =
    flight?.pricing?.PricedFlightOffers?.PricedFlightOffer?.[0]?.OfferPrice?.[0]
      ?.RequestedDate?.PriceDetail?.TotalAmount?.SimpleCurrencyPrice?.Code;
  // let currency = "ETB";

  useEffect(() => {
    dispatch(getBookingById(id));
    dispatch(getMyCompany());
  }, [dispatch, id]);

  useEffect(() => {
    if (user?.role === USER_ROLE_ENTERPRISE) {
      dispatch(getMyEnterprise());
      setPaymentMethod("webirr_cbe");
    }

    if (
      company &&
      currencyType(currency) === "USD" &&
      company?.USDCreditLimit > totalPrice
    ) {
      setPaymentMethod("USDCredit");
    } else {
      setPaymentMethod("");
    }

    if (
      company &&
      company.wallet < WALLET_MINIMUM_AMOUNT + priceToNumber(totalPrice()) &&
      currencyType(currency) === "USD"
      // passengerTypeState !== NON_DOCUMENT_HOLDER
    ) {
      setPaymentMethod("webirr_cbe");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.role, company]);

  const currentDate = new Date();

  const handleClick = async (otp) => {
    // e.preventDefault();
    const payment = paymentReq(flight.pricing, basePrice);

    if (
      user?.role !== USER_ROLE_SUPERAGENT &&
      user?.role !== USER_ROLE_EMPLOYEE &&
      !company
    ) {
      toast.error("Please Refresh the page and Try again");
      return;
    }

    let requestBooking = null;
    if (paymentMethod === "wallet" || paymentMethod === "credit") {
      requestBooking = {
        Query: {
          ...response.Query,
          ...payment,
        },
      };
    }

    const requestData = {
      basePrice,
      passengersData,
      files,
      payment: paymentMethod,
      payLater: false,
      samePrice: true,
      companyId: company?._id,
    };

    try {
      const res = await airBooking(requestBooking, requestData, setLoading);

      if (res?.Response?.TicketDocInfos) {
        toast.success("Booking Successful");
        navigate(`/ndc-done?${query}`, { state: { booking: res, fees } });
        return;
      }
      if (res?.VdcErrors?.Error) {
        setError(res?.VdcErrors?.Error?.[0]?.ShortText);
        return;
      }
    } catch (error) {
      const err = error.response?.data?.errors?.Error?.[0]?.Reason;
      toast.error(err || "Something went wrong");

      console.log(error);
      return;
    } finally {
      setLoading(false);
    }

    // if (
    //   company &&
    //   paymentMethod === "wallet" &&
    //   company.wallet < WALLET_MINIMUM_AMOUNT + priceToNumber(totalPrice())
    // ) {
    //   toast.error(
    //     `You can't use wallet for payment. You don't have enough balance. Please use other payment method.`
    //   );
    //   return;
    // }

    // if (!company && paymentMethod === "credit") {
    //   if (!selectedCorporate) {
    //     toast.error("Please select corporate");
    //     return;
    //   }
    // }

    // setLoading(true);
    // getTicket( otp)
    //   .then((res) => {
    //     console.log(res);
    //     if (res.TicketFailureInfo) {
    //       console.log(res);
    //       setError(res?.TicketFailureInfo?._attributes?.Message);
    //       setLoading(false);
    //     } else {
    //       setLoading(false);
    //       toast.success("Booking Successful");

    //       navigate(`/pay-done/${id}?${queryURL}`, {
    //         state: {
    //           ticket: res,
    //           charges,
    //           serviceCharge,
    //           queryURL,
    //           currency,
    //           rateDifference,
    //         },
    //       });
    //       return;
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //   });
  };

  const handleKachaSubmit = async (otp) => {
    handleClick(otp);
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      setError(null);
    }
  }, [error]);

  let issueAt = moment(currentDate).format("YYYY-MM-DDTHH:mm:ss");

  const getTicket = (requestData, otp) =>
    new Promise((resolve, reject) => {
      axios
        .post(API_URL + "ndc/air-booking", requestData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });

  const handleWithoutPayment = async (e) => {
    e.preventDefault();

    const requestData = {
      basePrice,
      passengersData,
      files,
      payment: paymentMethod,
      payLater: true,
    };

    try {
      const res = await airBooking(response, requestData, setLoading);
      if (res?.Response) {
        toast.success("Reservation Successful");
        navigate("/");
        return;
      }
    } catch (error) {
      const err = error.response?.data?.errors?.Error?.[0]?.Reason;
      toast.error(err || "Something went wrong");

      console.log(error);
      return;
    } finally {
      setLoading(false);
    }
  };

  const initiatePayment = async () => {
    if (paymentMethod === "kacha") {
      let totalPrincipal =
        parseFloat(basePrice) +
        parseFloat(fees?.charges || 0) +
        parseFloat(fees.ServiceCharge || 0);

      const kachaReq = {
        phone: company?.officeNumber,
        // principal: totalPrincipal.toString(),
        principal: "100",
        // phone: "251923130324",
        code: "80000030",
        // pnr_number: retrieve.Response?.Order[0].OrderID?.value,
      };

      try {
        setLoading(true);
        const [data, product] = await Promise.all([
          requestLoan(kachaReq),
          getLoanProduct(kachaReq.phone),
        ]);

        if (data?.initiate) {
          const modalData = {
            kacha: data?.initiate,
            id: booking?._id,
            issueTicket: true,
            product,
          };
          let modalType = "Kacha";
          setKachaInfo(data.initiate);

          dispatch(openModal({ modalType, data: modalData }));
        }
      } catch (err) {
        setError(err.message || "Something went wrong");
      } finally {
        setLoading(false);
      }

      return;
    }
    const data = {
      // pnr: retrieve.Response?.Order[0].OrderID?.value,
      paymentMethod,
    };

    await initiateLoanRequest(data, setLoading, setPayment);
  };

  // function tryAgain() {
  //   return (
  //     <div className="flex flex-col items-center justify-center">
  //       <button
  //         onClick={() => window.location.reload()}
  //         className="mt-5 bg-main hover:bg-main-900 text-white font-bold py-2 px-4 rounded-md
  //       focus:outline-none focus:shadow-outline "
  //       >
  //         Try Again
  //       </button>
  //     </div>
  //   );
  // }

  // if (!isLoading) {
  //   if (!booking || !user) {
  //     return tryAgain();
  //   }

  //   if (
  //     !company &&
  //     (user?.role === USER_ROLE_AGENT || user?.role === USER_ROLE_MEMBER)
  //   ) {
  //     return tryAgain();
  //   }
  // }

  const handleCorporateSubmit = (selectedCorporate) => {
    setShowCorporateList(false);
    setSelectedCorporate(selectedCorporate);
  };

  if (loading || isLoading) {
    return <Loading />;
  }

  function totalPrice() {
    const airPriceTotal = parseFloat(basePrice);
    // retrieve?.Response?.Order?.[0]?.TotalOrderPrice?.SimpleCurrencyPrice
    // ?.value

    let result =
      fees?.rateDifference > 1
        ? (airPriceTotal * fees?.rateDifference).toFixed(2)
        : airPriceTotal;

    result =
      parseFloat(result) +
      parseFloat(fees?.ServiceCharge || 0) +
      parseFloat(fees?.NDC_SC || 0) +
      parseFloat(fees?.Commission || 0);

    return convertNumbThousand(result) + " " + currency;
  }

  function priceToNumber(str) {
    let result = str?.toString().replace(/,/g, "");
    return +result;
  }

  let instructions = [];
  if (payment?.paymentInstructionsAll) {
    instructions = payment?.paymentInstructionsAll;
  } else {
    instructions = payment?.paymentInstructions;
  }

  const renderInstructions = () => {
    return (
      <div className="mt-2 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col tracking-wider">
          {instructions?.map((item, index) => (
            <div key={index}>
              <h1 className="text-xl text-center font-semibold underline">
                {item?.type}
              </h1>
              <div key={index} className="flex items-start">
                <div className="flex flex-col">
                  {item?.instructions?.map((instruction, i) => (
                    <span
                      className="text-sm font-semibold text-neutral-900"
                      key={i}
                    >
                      {instruction}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 shadow space-y-6 sm:space-y-1 sm:p-6 p-6 xl:px-8 xl:py-1">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <NcImage
              src={getCarrierLogo(
                flight?.FlightSegment?.[0]?.MarketingCarrier?.AirlineID?.value
              )}
            />
          </div>
          <div className="py-5 sm:px-5 space-y-3">
            <span className="text-lg text-neutral-500 font-semibold">
              <h1>
                {flight?.FlightSegment?.MarketingCarrier?.AirlineID?.value}
              </h1>
            </span>
            {flight?.FlightSegment?.map((segment, index) => (
              <div key={index}>
                <span className="text-lg text-neutral-500 font-semibold line-clamp-1">
                  <h1>
                    {segment.Departure.AirportCode.value} -{" "}
                    {segment.Arrival.AirportCode.value}
                  </h1>
                </span>
                <span className="text-base font-medium mt-1 block">
                  {moment(segment.Departure.Date).format("MMM DD YYYY")}-
                  {moment(segment.Arrival.Date).format("MMM DD YYYY")}
                </span>
                <span className="text-base font-medium mt-1 block">
                  {segment.Departure.Time} - {segment.Arrival.Time}
                </span>
              </div>
            ))}

            <div className="flex flex-row justify-between">
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {adults > 0 && parseInt(adults)} Adult
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {children > 0 && children + " Children"}
              </span>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {infants > 0 && infants + " Infants"}
              </span>
            </div>

            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Price detail</h3>
          <div className="flex flex-col justify-between text-neutral-600 gap-1">
            {getPriceEach(
              flight.pricing.PricedFlightOffers.PricedFlightOffer[0].OfferPrice
            )?.map((item, index) => {
              return (
                <div key={index} className="flex justify-between">
                  <p>
                    {item.count} *{" "}
                    {getTravelerType(
                      flight?.pricing?.DataLists?.AnonymousTravelerList,
                      item.type
                    )}
                  </p>
                  <p>
                    {/* {item.price} INR */}
                    {/* {convertNumbThousand(item.price)} {currency} */}
                    {convertNumbThousand(
                      getNDCPassengerPrice(
                        item,
                        adults,
                        children,
                        fees,
                        getTravelerType(
                          flight?.pricing?.DataLists?.AnonymousTravelerList,
                          item.type
                        )
                      )
                    )}{" "}
                    {currency}
                  </p>
                </div>
              );
            })}
          </div>
          {/* <div>
            <PassengerPricing
              label={+adults > 0 ? "Adults" : "Labors"}
              count={adults}
              priceIndex={0}
              AirPricing={airPrice}
              rateDifference={rateDifference}
            />
            <PassengerPricing
              label="Children"
              count={children}
              priceIndex={1}
              AirPricing={airPrice}
              rateDifference={rateDifference}
            />
            <PassengerPricing
              label="Infants"
              count={infants}
              priceIndex={children > 0 ? 2 : 1}
              AirPricing={airPrice}
              rateDifference={rateDifference}
            />
          </div> */}
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span>
              {fees?.charges !== 0 ? fees?.charges : fees?.ServiceCharge}{" "}
              {currencyType(currency)}
            </span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Agent Fee</span>
            <span>
              {fees?.Commission || 0} {currencyType(currency)}
            </span>
          </div>

          <div className="border-b border-neutral-200 "></div>
          <div className="flex justify-between font-bold text-xl text-black">
            <span>Total Price</span>
            <span>{totalPrice()}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-5 sm:p-6 xl:p-8 shadow">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Confirm and payment
        </h2>
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <div>
            <h3 className="text-2xl font-semibold ">Traveler Information</h3>
            <NcModal
              renderTrigger={(openModal) => (
                <span
                  onClick={() => openModal()}
                  className="block lg:hidden underline  mt-1 cursor-pointer"
                >
                  View booking details
                </span>
              )}
              renderContent={renderSidebar}
              modalTitle="Booking details"
            />
          </div>
          <div
            className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col 
          w-full sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 shadow"
          >
            <div className="px-1 md:mx-32 md:px-5 py-4  text-lg font-light">
              {passengersData?.passengers?.map((travel, index) => (
                <Fragment key={index}>
                  <h1 className="text-xl font-bold p-2">Passenger {++index}</h1>
                  <div className="flex flex-row justify-between px-4 space-x-12">
                    <h1 className="">Name</h1>
                    <span className="text-neutral-500 dark:text-neutral-400 ">
                      {travel?.FirstName} {travel?.LastName}
                    </span>
                  </div>
                  {travel?.Email && (
                    <div className="flex flex-row justify-between px-4 py-2 space-x-6">
                      <h1 className="">Email</h1>
                      <h1 className="text-neutral-500 dark:text-neutral-400 ">
                        {travel?.Email}{" "}
                      </h1>
                    </div>
                  )}

                  <div className="flex flex-row justify-between  px-4 py-2">
                    <h1 className="">Seat</h1>
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {travel?.AirSeatAssignment ? (
                        travel?.AirSeatAssignment instanceof Array ? (
                          travel?.AirSeatAssignment?.map((seat, index) => (
                            <span key={index}>{seat._attributes.Seat} </span>
                          ))
                        ) : (
                          <span>
                            {travel?.AirSeatAssignment?._attributes?.Seat}
                          </span>
                        )
                      ) : (
                        <span>Seat Not Selected</span>
                      )}
                    </span>
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>

        <div>
          {!payment?.paymentInstructions && (
            <PaymentMethodsList
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
              setShowCorporateList={setShowCorporateList}
              user={user}
              enterprise={enterprise}
              company={company}
              totalPrice={priceToNumber(passengersData?.TotalAmount)}
              setSelectedCorporate={setSelectedCorporate}
              // departureDate={
              //   retrieve?.AirSegment?.[0]?._attributes?.DepartureTime ||
              //   retrieve?.AirSegment?._attributes?.DepartureTime
              // }
              passengerTypeState={passengerTypeState}
              currency={currency}
            />
          )}
          {!DIRECT_PAYMENT.includes(paymentMethod) && payment && (
            <div className="border p-5 border-black shadow rounded-xl">
              <h1 className="text-2xl text-center font bold capitalize">
                {paymentMethod}
              </h1>
              <div className="px-2 flex justify-between flex-wrap">
                <h1 className="text-xl font-semibold">Your PNR code is:</h1>
                <button
                  className="bg-slate-300 text-white px-2 py-1 rounded-lg cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      // airReservation._attributes.LocatorCode
                      payment.paymentReference
                    );
                    toast.success("Payment Reference code copied to clipboard");
                  }}
                >
                  <h1 className="text-xl font-bold flex  ">
                    {payment.paymentReference}
                    {/* {retrieve.Response?.Order[0].OrderID?.value} */}
                    <button className="mx-2 hover:text-green-800  hover:rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                        />
                      </svg>
                    </button>
                  </h1>
                </button>
              </div>
              {renderInstructions()}
            </div>
          )}
        </div>

        <h1 className="text-xl font-semibold">
          Selected Payment Method:{" "}
          <span className="text-main">
            {
              paymentMethods.find((method) => method.name === paymentMethod)
                ?.title
            }
          </span>
        </h1>

        {paymentMethod === "wallet" ||
        paymentMethod === "credit" ||
        paymentMethod === "USDCredit" ? (
          <>
            <div className="lg:hidden row lg:p-0 space-y-2  ">
              <div className="col-12">
                <ButtonPrimary
                  className="bg-slate-50 
                  border-1 border-gray-500 text-black hover:text-white hover:bg-gray-400
                  focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 w-full rounded-3xl"
                  onClick={handleWithoutPayment}
                >
                  Continue Without Payment
                </ButtonPrimary>
              </div>
              <div className="col-12 ">
                <ButtonPrimary
                  onClick={() => handleClick()}
                  className="w-full 
                border-1 border-main text-black bg-slate-50 hover:text-white hover:bg-main-900
                focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 rounded-3xl"
                >
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>

            <div className="pt-8 lg:flex justify-between hidden gap-4">
              {/* <div className="w-full">
                <ButtonPrimary className="bg-red-500 hover:bg-red-400 py-3 w-44">
                  Cancel Booking
                </ButtonPrimary>
              </div> */}
              <div className="w-full">
                <ButtonPrimary
                  className="bg-slate-50 
                  border-1 border-gray-500 text-black hover:text-white hover:bg-gray-400
                  focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 w-full rounded-3xl"
                  onClick={handleWithoutPayment}
                >
                  {/* Continue Without Payment */}
                  Pay Later
                </ButtonPrimary>
              </div>
              <div className="w-full">
                <ButtonPrimary
                  onClick={() => handleClick()}
                  className="w-full 
                border-1 border-main text-black bg-slate-50 hover:text-white hover:bg-main-900
                focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 rounded-3xl
                "
                >
                  Confirm and pay
                </ButtonPrimary>
              </div>
            </div>
          </>
        ) : paymentMethod === "" ? (
          <div className="row lg:p-0 space-y-3  ">
            <div className="col-12">
              <ButtonPrimary
                className="bg-gray-400 text-white hover:bg-gray-300 w-full"
                // onclick go home
                onClick={() => navigate("/")}
              >
                No Payment Method Selected
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <div className="row lg:p-0 space-y-3  ">
            <div className="col-12">
              <ButtonPrimary
                className="bg-green-500 py-3 hover:bg-green-400 w-full"
                // onClick={initiatePayment}
              >
                Proceed
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage  px-1 py-4`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className=" lg:block flex-grow mb-10">{renderSidebar()}</div>
      </main>
      {paymentMethod === "credit" && (
        <CorporateList
          show={showCorporateList}
          handleClose={handleCorporateSubmit}
        />
      )}
      {paymentMethod === "kacha" && (
        <KachaCreditOTP handleSubmit={handleKachaSubmit} booking={booking} />
      )}
    </div>
  );
};

export default NDCInstantPayment;
