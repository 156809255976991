import {
  DEFAULT_SERVICE_BUSINESS_INTL_CHARGE,
  DEFAULT_SERVICE_INTL_CHARGE,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_SUPERAGENT,
} from "../common/constants";

export const getServiceChargeInternational = (price, charges) => {
  if (charges?.length > 1 && charges[0].maxPrice) {
    const serviceChargeInternational = charges.find(
      (rule) => price >= rule.minPrice && price <= rule.maxPrice
    );

    return serviceChargeInternational?.charge || DEFAULT_SERVICE_INTL_CHARGE;
  } else {
    let serviceChargeInternational;

    if (price >= 0 && price <= 34999) {
      serviceChargeInternational = 11;
    } else if (price >= 35000 && price <= 49999) {
      serviceChargeInternational = 15;
    } else if (price >= 50000 && price <= 59999) {
      serviceChargeInternational = 18;
    } else if (price >= 60000 && price <= 69999) {
      serviceChargeInternational = 22;
    } else if (price >= 70000 && price <= 79999) {
      serviceChargeInternational = 25;
    } else if (price >= 80000 && price <= 89999) {
      serviceChargeInternational = 29;
    } else if (price >= 90000 && price <= 99999) {
      serviceChargeInternational = 33;
    } else if (price >= 100000) {
      serviceChargeInternational = 36;
    }
    return serviceChargeInternational;
  }
};

export const getBusinessServiceChargeInternational = (price, charge) => {
  console.log(charge, price);
  if (charge) {
    return charge;
  }

  return DEFAULT_SERVICE_BUSINESS_INTL_CHARGE;
  // if (charges?.length > 1) {
  //   const businessServiceChargeInternational = charges.find(
  //     (rule) => price >= rule.minPrice && price <= rule.maxPrice
  //   );

  //   return (
  //     businessServiceChargeInternational?.charge || DEFAULT_SERVICE_INTL_CHARGE
  //   );
  // } else {
  //   let businessServiceChargeInternational;

  //   if (price >= 0 && price <= 34999) {
  //     businessServiceChargeInternational = 14;
  //   } else if (price >= 35000 && price <= 49999) {
  //     businessServiceChargeInternational = 18;
  //   } else if (price >= 50000 && price <= 69999) {
  //     businessServiceChargeInternational = 21;
  //   } else if (price >= 70000 && price <= 89999) {
  //     businessServiceChargeInternational = 25;
  //   } else if (price >= 90000 && price <= 109999) {
  //     businessServiceChargeInternational = 28;
  //   } else if (price >= 110000 && price <= 129999) {
  //     businessServiceChargeInternational = 32;
  //   } else if (price >= 130000 && price <= 149999) {
  //     businessServiceChargeInternational = 35;
  //   } else if (price >= 150000 && price <= 199999) {
  //     businessServiceChargeInternational = 48;
  //   } else if (price >= 200000) {
  //     businessServiceChargeInternational = 57;
  //   }

  //   return businessServiceChargeInternational;
  // }
};

export const getNDCTotalPrice = (offer, user, count = 1, companySC) => {
  if (companySC?.length > 0) return 0;
  if (
    user?.role === USER_ROLE_SUPERAGENT ||
    user?.role === USER_ROLE_EMPLOYEE
  ) {
    return 0;
  }

  const NDCSC = [
    { carrier: "EK", oneWay: 0, round: 0 },
    { carrier: "KQ", oneWay: 800, round: 1500 },
    { carrier: "QR", oneWay: 300, round: 1100 },
    { carrier: "TK", oneWay: 1000, round: 2000 },
  ];

  const carrier = offer?.OfferID?.Owner;
  const { Associations } = offer?.PricedOffer;

  if (!Associations) return;

  let charge = NDCSC?.find((item) => item.carrier === carrier);

  if (Associations?.length > 1) charge = charge?.round;
  else charge = charge?.oneWay;

  return charge * count;
};
